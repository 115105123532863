import { Container } from "react-bootstrap";
import UserTable from "./UserTable";
import { User } from "./interfaces/User";
import { currentUserIsAdmin } from "../../firebase";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { requestWithAuthorization } from "../../network/apiRequests";
import CometSpinner from "../CometSpinner";

import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function DashboardTable() {
  const token = useAppSelector((state) => state.auth.token);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();

  // function to fetch all users from the backend
  async function fetchUsers() {
    setIsLoading(true);
    try {
      const response = await requestWithAuthorization(
        process.env.REACT_APP_API_HOSTNAME + "/users"
      );

      if (response.status === 200) {
        setUserData(response.data);
      } else {
        navigate("/404");
      }
    } catch (error) {
      console.error(error);
      navigate("/404");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    currentUserIsAdmin().then((result) => {
      if (result) {
        fetchUsers();
      } else {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user?.uid) {
          navigate("/recordings/" + user?.uid);
        } else {
          navigate("/404");
        }
      }
    });
    setIsLoading(false);
  }, [token, navigate]);

  const spinnerContainer = (
    <Container
      style={{
        backgroundColor: "#091014",
        minHeight: "100vh",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CometSpinner height={100} />
    </Container>
  );

  if (isLoading) {
    return spinnerContainer;
  }

  if (!userData) {
    return <h1>No Data to show</h1>;
  }

  return <UserTable users={userData as User[]} />;
}
