import { useEffect, useState } from "react";
import { Container, Stack } from "react-bootstrap";
import ConnectionContainer from "./connections/ConnectionContainer";

export default function SettingsComponent() {
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [activeNavigation, setActiveNavigation] = useState<string>("account");

  useEffect(() => {
    handleActiveNavigationChange();
  }, [activeNavigation]);

  function handleActiveNavigationChange() {
    const identifier = activeNavigation;
    if (identifier === "account") {
      setContent(
        <div>
          <h1>Account</h1>
        </div>
      );
      return;
    }
    if (identifier === "connections") {
      setContent(<ConnectionContainer />);
      return;
    }
    if (identifier === "security") {
      setContent(
        <div>
          <h1>Security</h1>
        </div>
      );
      return;
    }
  }
  return (
    <Container
      fluid
      className="background-color-light text-white p-0 border-radius-4"
    >
      <Container fluid className="p-3">
        <Stack direction="horizontal" className="justify-content-between pb-3">
          <Container
            fluid
            className="p-0 settings-navigation-item"
            onClick={() => setActiveNavigation("account")}
          >
            <div className="text-center">
              <span>Account</span>
            </div>
          </Container>
          {/* <Container
            fluid
            className="p-0 settings-navigation-item"
            onClick={() => setActiveNavigation("connections")}
          >
            <div className="text-center">
              <span>Connections</span>
            </div>
          </Container> */}
          <Container
            fluid
            className="p-0 settings-navigation-item"
            onClick={() => setActiveNavigation("security")}
          >
            <div className="text-center">
              <span>Security</span>
            </div>
          </Container>
        </Stack>
        {content}
      </Container>
    </Container>
  );
}
