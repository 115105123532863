import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

interface FormButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  text: React.ReactNode;
  isLoading: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FormButton: React.FC<FormButtonProps> = ({ type, text, isLoading, onClick }) => {
  return (
    <Button
      variant="light"
      type={type}
      className="mt-5 wind-btn w-100 p-3"
      disabled={isLoading}
      size="lg"
      onClick={onClick}
      style={{height: '60px'}}
    >
      {isLoading ? <Spinner animation="border" variant="light" /> : text}
    </Button>
  );
};

export default FormButton;
