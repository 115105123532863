import { BsCalendar4 } from "react-icons/bs";
import DropdownButton from "./DropdownButton";
import Calendar from "../dashboard/Calendar";


interface CalendarButtonProps {
    startDate: Date;
    endDate: Date;
}

export default function CalendarButton( { startDate, endDate }: CalendarButtonProps) {
    return (
        <DropdownButton icon={<BsCalendar4 size={"20px"} />} text={startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString()} dropdownContent={<Calendar />} />
    )
}