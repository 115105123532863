import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

interface FormSwitchHintProps {
    question: string;
    linkText: string;
    linkTo: string;
}

const FormSwitchHint: React.FC<FormSwitchHintProps> = ({ question, linkText, linkTo }) => {
    return (
        <Row className="pt-4">
            <Col className='form-quest'>
                <p>{question} <Link to={linkTo} className='wind-link'>{linkText}</Link></p>
            </Col>
        </Row>
    );
  };

  export default FormSwitchHint