import { Form, InputGroup } from "react-bootstrap"
import { BsSearch } from "react-icons/bs"

interface SearchbarProps {
    classes?: string;
}

export default function Searchbar( { classes }: SearchbarProps) {
    const styling = " silver-border border-radius-4"
    if (!classes) {
        classes = styling;
    } else {
        classes += styling;
    }
    return (
        <InputGroup className={classes}>
            <InputGroup.Text className="transparent-background color-silver no-border">
                <BsSearch />
            </InputGroup.Text>
            <Form.Control type="search" placeholder="Search" aria-label="Search" className="color-silver transparent-background search-text no-border" />
        </InputGroup>
    )
}