import { Alert } from "react-bootstrap";

interface FormMessageFieldProps {
    message: string | null;
    error?: string | null;
}

export default function FormMessageField({ message, error }: FormMessageFieldProps) {
    return (
        <>
            {<Alert variant="success" show={message !== null}>{message}</Alert>}
            {<Alert variant="danger" show={error !== null}>{error}</Alert>}
        </>
    )
}