import { Col, Container, Row } from "react-bootstrap";
import DashboardTable from "../components/dashboard/DashboardTable";


export default function UsersPage() {
    return (
        <Container fluid className="pt-2 background-color">
            <Row>
                <Col>
                    <DashboardTable />
                </Col>
            </Row>
        </Container>
    )
}