import { Breadcrumb } from "react-bootstrap";
import { HiOutlineChevronDoubleRight } from "react-icons/hi";
import { Link } from "react-router-dom";

export default function CustomBreadcrumbs(props: any) {

    const path: {path: string, title: string}[] | undefined = props.path
    const page: string | undefined = props.page

    const pathCrumbs = path?.map((pathElement) => {
        return (
            <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: pathElement.path }}>{pathElement.title}</Breadcrumb.Item>
            <Breadcrumb.Item active><HiOutlineChevronDoubleRight /></Breadcrumb.Item>
            </>
        )
    })

    return (
        <Breadcrumb>
            {pathCrumbs}
            <Breadcrumb.Item active className="breadcrumbs-active">{page}</Breadcrumb.Item>
        </Breadcrumb>
    )
}