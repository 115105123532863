import { Button } from "react-bootstrap";

interface DashboardButtonProps {
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export default function DashboardButton({
  text,
  icon,
  onClick,
  disabled,
}: DashboardButtonProps) {
  return (
    <Button
      variant="light"
      className="wind-btn wind-btn-small px-3 d-flex align-items-center"
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      <span className={icon ? "ps-2" : ""}>{text}</span>
    </Button>
  );
}
