import { Dropdown } from "react-bootstrap";

interface DropdownButtonProps {
    icon: React.ReactNode;
    text: string;
    dropdownContent: React.ReactNode;
    endAlign?: boolean;
}

export default function DropdownButton( { icon, text, dropdownContent, endAlign }: DropdownButtonProps) {
    return (
        <Dropdown className="custom-dropdown">
            <Dropdown.Toggle id="dropdown-basic" className="dropdown-btn dropdown-btn-text align-items-center px-3" bsPrefix="p-2">
                <span className="pe-2">{icon}</span>
                <span>{text}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu {...(endAlign ? { align: "end" } : {})}>
                {dropdownContent}
            </Dropdown.Menu>
        </Dropdown>
    )
}