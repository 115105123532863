import { Container, Row, Col, Spinner } from "react-bootstrap";
import CustomBreadcrumbs from "../../navigation/breadcrumbs/CustomBreadcrumbs";
import DashboardButton from "../../dashboard/DashboardButton";
import { RiDeleteBinLine } from "react-icons/ri";
import KPICard from "../../dashboard/card/KPICard";
import GraphCard from "./GraphCard";
import BigCard from "../../dashboard/card/BigCard";
import CustomMapContainer from "../../map/CustomMapContainer";
import { requestWithAuthorization } from "../../../network/apiRequests";
import { useEffect, useState } from "react";
import { getTimePassed } from "../../../utils/TimePassed";
import { createKPIArrayFromSessionInfos } from "../../../utils/KPIExtractor";
import ScalarValuesContainer from "./ScalarValuesContainer";
import MapCard from "../../dashboard/card/MapCard";
import GraphsContainer from "./GraphsContainer";
import ConnectStravaActivityButton from "./ConnectStravaActivityButton";
import UploadFITFileButton from "./UploadFITFileButton";

interface SessionDetailComponentProps {
  sessionId: string;
}

interface SessionData {
  name: string;
  startDateTime: Date;
  endDateTime: Date;
  infos: any;
}

export default function SessionDetailComponent({
  sessionId,
}: SessionDetailComponentProps) {
  async function fetchRecordingDetails(recording_id: string) {
    try {
      const response = await requestWithAuthorization(
        process.env.REACT_APP_API_HOSTNAME +
          "/recordings/" +
          recording_id +
          "/details"
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchRecordingDetails(sessionId!)
      .then((result) => {
        // convert start and end date to Date-objects
        result.startDateTime = new Date(result.startDateTime);
        result.endDateTime = new Date(result.endDateTime);
        setSessionData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sessionId]);

  let containerContent = <></>;
  if (loading) {
    containerContent = (
      <Row>
        <Col className="d-flex justify-content-center">
          <Spinner animation="border" variant="light" />
        </Col>
      </Row>
    );
  } else if (sessionData === null) {
    containerContent = (
      <Row>
        <Col className="d-flex justify-content-center">
          <h1>Session not found</h1>
        </Col>
      </Row>
    );
  } else {
    const timePassedString = getTimePassed(
      sessionData?.startDateTime.toISOString()
    );
    const KPIs = createKPIArrayFromSessionInfos(sessionData?.infos);
    containerContent = (
      <>
        <Row>
          <Col>
            <Row>
              <Col>
                <h1>{`${sessionData.name}`}</h1>
              </Col>
              <Col className="d-flex justify-content-end">
                <UploadFITFileButton />
                {/* <ConnectStravaActivityButton /> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="detail-page-subheading">{`${sessionData?.startDateTime?.toLocaleDateString()} - ${sessionData?.startDateTime?.toLocaleTimeString()}`}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <ScalarValuesContainer
          timePassedString={timePassedString}
          values={sessionData?.infos}
        />
        <Row className="pt-1">
          <Col xl={8} className="pe-1">
            {/* Left side */}
            <Row>
              <Col>
                <KPICard
                  sessionID={sessionId}
                  heading={sessionData?.name}
                  subheading={sessionData?.endDateTime?.toLocaleDateString()}
                  kpis={KPIs}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={4} className="ps-1">
            {/* Right side */}
            <Row className="pb-2">
              <Col>
                <MapCard sessionID={sessionId} />
              </Col>
            </Row>
          </Col>
        </Row>
        <GraphsContainer sessionId={sessionId} />
      </>
    );
  }

  return (
    <Container fluid className="background-color text-white p-4">
      <Row>
        <Col>
          <CustomBreadcrumbs
            path={[
              { path: "/dashboard", title: "Dashboard" },
              { path: "/sessions", title: "Sessions" },
            ]}
            page={`${sessionData !== null ? sessionData.name : "Detail"}`}
          />
        </Col>
      </Row>
      <Container fluid className="pt-4 pb-2 px-1">
        {containerContent}
      </Container>
    </Container>
  );
}
