import { Container, Form } from "react-bootstrap"
import FormField from "../forms/FormField"
import { useEffect, useState } from "react"
import FormCheckbox from "../forms/FormCheckbox"
import FormButton from "../forms/FormButton"
import { Link } from "react-router-dom"
import FormSwitchHint from "../forms/FormSwitchHint"
import FormMessageField from "../forms/FormMessageField"
import { signUp, updateName } from "../../firebase"
import { useNavigate } from "react-router-dom";

export default function SignupForm() {
    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [terms, setTerms] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const navigate = useNavigate();
    const countdown = 5;

    const resetFormFields = () => {
        return (
            setFirstName(''),
            setLastName(''),
            setEmail(''),
            setPassword(''),
            setTerms(false)
        )
    }

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerms(event.target.checked)
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        setMessage(null);

        try {
            if (firstname.search(" ") !== -1 || lastname.search(" ") !== -1) {
                setError("First and last name cannot contain spaces.");
                return
            }

            if (!firstname || !lastname || !email || !password) {
                setError("Please fill out all fields.");
                return
            }

            if (!terms) {
                setError("Please agree to the terms and conditions.");
                return
            }

            // check length of email
            if (email.length < 4) {
                setError("Email address is invalid.");
                return
            }

            await signUp(email, password)
            await updateName(firstname, lastname)
            setMessage("Account created successfully. You will be redirected to the login page in " + countdown + " seconds.");
            resetFormFields();
            // Start the countdown
            setTimeout(() => navigate("/login"), countdown * 1000);

        } catch (error:any) {
            var errorCode = error.code;
            if (errorCode === 'auth/email-already-in-use') {
                setError("Email address is already in use.");
                return
            }
            if (errorCode === 'auth/invalid-email') {
                setError("Email address is invalid.");
                return
            }
            if (errorCode === 'auth/weak-password') {
                setError("Password is too weak.");
                return
            }
            setError("Something went wrong. Please try signing up again.\n" + error.message)
        } finally {
            setIsLoading(false);
        }
    }

    const termsCheckboxLabel = (
        <span >I agree to the <Link className="wind-link" to={"/terms"}>Terms and Conditions</Link></span>
    )

    return (
        <Container fluid className="background-color" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Form className="w-100-per">
                <FormField controlId="formBasicFirstName" label="First Name" type="text" placeholder="Enter your first name" value={firstname} onChange={handleFirstNameChange} />
                <FormField controlId="formBasicLastName" label="Last Name" type="text" placeholder="Enter your last name" value={lastname} onChange={handleLastNameChange} />
                <FormField controlId="formBasicEmail" label="Email address" type="email" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
                <FormField controlId="formBasicPassword" label="Password" type="password" placeholder="Enter your password" value={password} onChange={handlePasswordChange} />
                <FormCheckbox controlId="formBasicTermsCheckbox" label={termsCheckboxLabel} checked={terms} onChange={handleTermsChange}/>
                <FormMessageField message={message} error={error} />
                <FormButton type="submit" text="Sign up" isLoading={isLoading} onClick={handleSubmit} />
            </Form>
            <FormSwitchHint question="Already a member?" linkText="Sign in" linkTo="/login" />
        </Container>
    )
}