import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import { formatUTCTimestamp } from "../../utils/DateFormatter";
import { Container, Spinner, Stack } from "react-bootstrap";
import { HiMagnifyingGlassMinus } from "react-icons/hi2";
import useZoomableChart from "../sessions/detail/useZoomableChart";

interface LineChartComponentProps {
  recordingId: string;
  xKey: string;
  yKey: string[];
}

export default function LineChartComponent(
  this: any,
  props: LineChartComponentProps
) {
  const {
    plotData,
    handleMouseDown,
    handleMouseMove,
    zoom,
    resetZoom,
    refAreaLeft,
    refAreaRight,
    isLoading,
  } = useZoomableChart(props.recordingId, props.xKey, props.yKey);

  return (
    <Container>
      <Stack
        gap={2}
        direction="horizontal"
        className="justify-content-end pe-4"
      >
        <Spinner variant="light" size="sm" hidden={!isLoading} />
        <HiMagnifyingGlassMinus
          onClick={resetZoom}
          style={{ cursor: "pointer" }}
        />
      </Stack>
      <Container
        className="mx-0 px-0"
        style={{ height: "30vh", width: "100%" }}
      >
        <ResponsiveContainer width={"100%"} height={"90%"}>
          <LineChart
            data={plotData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
            onMouseDown={handleMouseDown.bind(this)}
            onMouseMove={handleMouseMove.bind(this)}
            onMouseUp={zoom.bind(this)}
          >
            <CartesianGrid strokeDasharray="0" vertical={false} opacity={0.2} />
            <XAxis
              dataKey={props.xKey}
              className="chart-label"
              stroke="#E0E0E0"
              dy={10}
              tick={{ fill: "#7E7E7E" }}
              tickFormatter={(value) => {
                if (value.includes("T")) {
                  return formatUTCTimestamp(value);
                }
                return value;
              }}
            />
            <YAxis
              className="chart-label"
              axisLine={false}
              tickLine={false}
              dx={-10}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {props.yKey.map((key, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={key}
                stroke={
                  index === 0
                    ? "#FF3282"
                    : index === 1
                    ? "#70349D"
                    : index === 2
                    ? "#B26691"
                    : "#8A5FA8"
                }
                activeDot={{ r: 8 }}
                dot={false}
                animationDuration={500}
              />
            ))}
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      </Container>
    </Container>
  );
}
