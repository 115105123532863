import { Container, Row, Col } from "react-bootstrap";
import Symbol from "./dashboard/card/Symbol";

export default function IconTile({
  icon,
  text,
}: {
  icon: JSX.Element;
  text: string;
}) {
  return (
    <Container className="p-4 d-flex justify-content-center text-center">
      <Row>
        <Col>
          <Row className="pb-2">
            <Col className="text-center d-flex justify-content-center">
              <Symbol symbol={icon} />
            </Col>
          </Row>
          <Row className="pb-2">
            <Col>
              <span className="session-tile-title">{text}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
