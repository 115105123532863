import { useState } from "react";
import { Button, Image, Spinner } from "react-bootstrap";

export default function ConnectButton({
  icon,
  text,
  onClick,
  classes,
}: {
  icon: JSX.Element;
  text: string;
  onClick: () => void;
  classes?: string;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const content = (
    <div className="d-flex justify-content-center align-items-center">
      <span className="me-2">{icon}</span>
      <span>{text}</span>
    </div>
  );
  return (
    <Button
      variant="light"
      // type={type}
      className={"w-100 " + classes}
      disabled={isLoading}
      size="lg"
      onClick={onClick}
      style={{ height: "60px" }}
    >
      {isLoading ? <Spinner animation="border" variant="light" /> : content}
    </Button>
  );
}
