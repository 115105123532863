import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { AuthState, logoutUser } from './authSlice';
import { ThunkDispatch, AnyAction, Dispatch } from '@reduxjs/toolkit';

export function monitorAuthState(token: string, dispatch: ThunkDispatch<{ auth: AuthState; }, undefined, AnyAction> & Dispatch<AnyAction>) {
  const auth = getAuth();

  onAuthStateChanged(auth, (user: User | null) => {
    if (user) {
      user.getIdToken().then((newToken) => {
        if (newToken === token) {
          return true
        } else {
          dispatch(logoutUser());
        }
      });
    } else {
      dispatch(logoutUser());
    }
    return false
  });
}

