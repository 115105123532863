import { MapContainer, TileLayer, CircleMarker, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { GPSCoorindate } from "../../models/GPSCoorindate";

interface MapContainerProps {
  points: Array<GPSCoorindate>;
  height: string;
}

export default function CustomMapContainer({
  points,
  height,
}: MapContainerProps) {
  const noDataContent = <h1>No data available</h1>;
  if (!points) {
    return noDataContent;
  }

  // check if every point has the attributes lat and lon
  if (!points.every((point) => point.lat && point.lon)) {
    return noDataContent;
  }

  if (points.length < 2) {
    return noDataContent;
  }

  const start = points[0];
  const end = points[points.length - 1];

  return (
    <MapContainer
      style={{ height: height, width: "100%", borderRadius: "1em" }}
      center={[start.lat, start.lon]}
      zoom={13}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        opacity={0.5}
        className="map-tile"
      />

      <CircleMarker
        center={{ lat: start.lat, lng: start.lon }}
        pathOptions={{ color: "#067E93", fillOpacity: 1 }}
        radius={10}
      />
      <CircleMarker
        center={{ lat: end.lat, lng: end.lon }}
        pathOptions={{ color: "white", fillColor: "#067E93", fillOpacity: 1 }}
        radius={10}
      />
      <Polyline
        pathOptions={{ color: "black" }}
        positions={
          /* convert array of gpscoordinate to array for leaflet (lat: xx, lng: yy) */
          points.map((point) => {
            return { lat: point.lat, lng: point.lon };
          })
        }
      />
    </MapContainer>
  );
}
