import { Col, Container, Row } from "react-bootstrap";
import CustomBreadcrumbs from "../components/navigation/breadcrumbs/CustomBreadcrumbs";
import SettingsComponent from "../components/settings/SettingsComponent";

export default function SettingsPage() {
  return (
    <Container fluid className="background-color text-white p-4">
      <Row>
        <Col>
          <CustomBreadcrumbs
            path={[{ path: "/dashboard", title: "Dashboard" }]}
            page="Settings"
          />
        </Col>
      </Row>
      <Container fluid className="p-0">
        <SettingsComponent />
      </Container>
    </Container>
  );
}
