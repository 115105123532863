import {
  FaBalanceScaleLeft,
  FaMountain,
  FaTemperatureLow,
} from "react-icons/fa";
import { FaGauge } from "react-icons/fa6";
import { WiHumidity } from "react-icons/wi";
import SmallCard from "../../dashboard/card/SmallCard";
import { Col, Row } from "react-bootstrap";
import { GiWindsock } from "react-icons/gi";
import { TbAngle, TbWaveSine } from "react-icons/tb";

export default function ScalarValuesContainer({
  values,
  timePassedString,
}: {
  values: { [key: string]: any };
  timePassedString: string;
}) {
  return (
    <Row className="pt-2 ps-2 pe-2">
      <Col
        key={"temperature"}
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={3}
        className="p-1"
      >
        <SmallCard
          name={"Avg. Temperature"}
          value={values?.avg_temp?.value}
          unit={values?.avg_temp?.unit}
          symbol={<FaTemperatureLow />}
          fromWhen={timePassedString}
          description={""}
        />
      </Col>
      <Col key={"speed"} xs={12} sm={6} md={4} lg={4} xl={3} className="p-1">
        <SmallCard
          name={"Avg. Speed"}
          value={values?.avg_speed?.value}
          unit={values?.avg_speed?.unit}
          symbol={<FaGauge />}
          fromWhen={timePassedString}
          description={""}
        />
      </Col>
      <Col key={"humidity"} xs={12} sm={6} md={4} lg={4} xl={3} className="p-1">
        <SmallCard
          name={"Avg. Humidity"}
          value={values?.avg_hum?.value}
          unit={values?.avg_hum?.unit}
          symbol={<WiHumidity />}
          fromWhen={timePassedString}
          description={""}
        />
      </Col>
      {/* <Col key={"maxalt"} xs={12} sm={6} md={4} lg={4} xl={3} className="p-1">
        <SmallCard
          name={"Max. Altitude"}
          value={values?.max_altitude?.value}
          unit={values?.max_altitude?.unit}
          symbol={<FaMountain />}
          fromWhen={timePassedString}
          description={"Maximum altitude"}
        />
      </Col> */}
      <Col
        key={"windspeed"}
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={3}
        className="p-1"
      >
        <SmallCard
          name={"Avg. Wind Speed"}
          value={values?.avg_windspeed?.value}
          unit={values?.avg_windspeed?.unit}
          symbol={<GiWindsock />}
          fromWhen={timePassedString}
          description={""}
        />
      </Col>
      {/* <Col key={"alpha"} xs={12} sm={6} md={4} lg={4} xl={3} className="p-1">
        <SmallCard
          name={"Avg. Alpha"}
          value={values?.avg_alpha?.value}
          unit={values?.avg_alpha?.unit}
          symbol={<TbAngle />}
          fromWhen={timePassedString}
          description={"Left/Right angle"}
        />
      </Col>
      <Col key={"beta"} xs={12} sm={6} md={4} lg={4} xl={3} className="p-1">
        <SmallCard
          name={"Avg. Beta"}
          value={values?.avg_beta?.value}
          unit={values?.avg_beta?.unit}
          symbol={<TbAngle />}
          fromWhen={timePassedString}
          description={"Up/Down angle"}
        />
      </Col>
      <Col
        key={"stability"}
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={3}
        className="p-1"
      >
        <SmallCard
          name={"Avg. Stability"}
          value={values?.avg_stability?.value}
          unit={values?.avg_stability?.unit}
          symbol={<FaBalanceScaleLeft />}
          fromWhen={timePassedString}
          description={"Stability score"}
        />
      </Col>
      <Col key={"uneven"} xs={12} sm={6} md={4} lg={4} xl={3} className="p-1">
        <SmallCard
          name={"Avg. Unevenness"}
          value={values?.avg_unevenness?.value}
          unit={values?.avg_unevenness?.unit}
          symbol={<TbWaveSine />}
          fromWhen={timePassedString}
          description={"Road unevenness"}
        />
      </Col> */}
    </Row>
  );
}
