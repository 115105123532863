import LoginForm from "../components/login/LoginForm";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import TwoColumns from "../components/TwoColumns";
import LoginImage from "../components/login/LoginImage";
import LoginFormContainer from "../components/login/LoginFormContainer";


export default function LoginPage() {

    const authError = useAppSelector((state) => state.auth.error)
    const [showErrorAlert, setShowErrorAlert] = useState(false)

    useEffect(() => {
        if (authError != null && authError != ""){
            setShowErrorAlert(true)
        } else {
            setShowErrorAlert(false)
        }
    },[authError])


    const leftContent = (
        <LoginImage />
    )

    const rightContent = (
        <LoginFormContainer />
    )

    return (
        <TwoColumns leftContent={leftContent} rightContent={rightContent} />
    )
    

    return (
        <Container fluid style={{ backgroundColor: '#091014', minHeight: '100vh', color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Row>
                <Col>
                    <h1>Login to BreezeBoard</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert key={"login-alert"} variant="danger" show={showErrorAlert} className="mt-4">
                        <Alert.Heading>Oh Snap! There was an error!</Alert.Heading>
                        <p>
                            {authError}
                        </p>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <LoginForm />
                </Col>
            </Row>
        </Container>
    )
}