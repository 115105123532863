import { BsBicycle } from "react-icons/bs";
import Symbol from "./Symbol";
import { AiOutlineRight } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


interface SessionRowProps {
    sessionID: string;
    sessionName: string;
    sessionDuration: string;
    sessionDate: string;
    lastSession: boolean;
}

export default function SessionRow( { sessionID, sessionName, sessionDuration, sessionDate, lastSession }: SessionRowProps) {
    var rowClasses = "";
    if (lastSession) {
        rowClasses = "session-row align-items-center py-2 session-row-last";
    } else {
        rowClasses = "session-row align-items-center py-2";
    }

    return (
        <Link to={`/sessions/${sessionID}`} className="text-decoration-none text-white">
            <Row className={rowClasses}>
                <Col xs={1}><Symbol symbol={<BsBicycle />} /></Col>
                <Col>
                    <Row>
                        <Col><span className="session-row-heading">{sessionName}</span></Col>
                    </Row>
                    <Row>
                        <Col><span className="session-row-subheading">{sessionDate} - {sessionDuration}</span></Col>
                    </Row>
                </Col>
                <Col xs={1} className="d-flex justify-content-end"><AiOutlineRight className="session-row-arrow" /></Col>
            </Row>
        </Link>
    )
}