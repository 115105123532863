
interface SymbolProps {
    symbol: React.ReactNode;
}

export default function Symbol ({ symbol }: SymbolProps) {
    return (
        <div className="d-flex justify-content-center align-items-center circle p-3 symbol-font">
            {symbol}
        </div>
    )
}