import { Col, Row, Spinner } from "react-bootstrap";

interface BigCardProps {
  heading: string;
  subheading?: string;
  button?: React.ReactNode;
  content: React.ReactNode;
  bigHeading?: boolean;
  loading?: boolean;
}

export default function BigCard({
  heading,
  subheading,
  button,
  content,
  bigHeading,
  loading,
}: BigCardProps) {
  let headingContent = <span className="card-heading">{heading}</span>;
  if (bigHeading !== undefined && bigHeading === true) {
    headingContent = <span className="card-big-heading">{heading}</span>;
  }

  if (loading) {
    return (
      <Row className="mx-0 background-color-light rounded-corners p-3">
        <Col>
          <Row>
            <Col>
              <span className="card-heading">{"Details"}</span>
            </Col>
          </Row>
          <Row>
            <Col className="w-100 justify-content-center text-center">
              <Spinner animation="border" variant="light" />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="mx-0 background-color-light rounded-corners p-3">
      <Col>
        {/* heading-row */}
        <Row>
          <Col>
            <Row>
              <Col>{headingContent}</Col>
            </Row>
            <Row>
              <Col>
                <span className="card-text">
                  {subheading !== undefined ? subheading : ""}
                </span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="align-items-center h-100">
              <Col className="d-flex justify-content-end">{button}</Col>
            </Row>
          </Col>
        </Row>
        {/* content-row */}
        <Row className="pt-5">
          <Col>{content}</Col>
        </Row>
      </Col>
    </Row>
  );
}
