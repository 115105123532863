import { Container, Row, Col } from "react-bootstrap";
import LoginForm from "./LoginForm";
import FormContainer from "../forms/FormContainer";


export default function LoginFormContainer() {
    return (
        <FormContainer heading="Sign In" children={<LoginForm />} />
    )
    return (
        <Container fluid className='background-color no-padding vh-100 d-flex align-items-center justify-content-center'>
            <Container fluid className='background-color' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row className="mx-0">
                    <Col><h1 className="heading">Sign In</h1></Col>
                </Row>
                <Row className="mx-0 w-100-per">
                    <Col className="p-5"><LoginForm /></Col>
                </Row>
            </Container>
        </Container>
    )
}