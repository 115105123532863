import { useParams } from "react-router-dom"
import SessionDetailComponent from "../components/sessions/detail/SessionDetailComponent"




export default function SessionPage() {

    let { sessionId } = useParams()
    if (sessionId === undefined) {
        return (
            <h1>Session ID not found</h1>
        )
    }
    return (
        <SessionDetailComponent sessionId={sessionId} />
    )
}