import { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Stack } from "react-bootstrap";
import { requestWithAuthorization } from "../../../network/apiRequests";
import ScrollableContainer from "../../ScrollableContainer";
import FilterCheckbox from "../filters/FilterCheckbox";
import DropdownButton from "../DropdownButton";
import { BsFilter } from "react-icons/bs";
import GraphCard from "./GraphCard";

type GraphsContainerProps = {
  sessionId: string;
};

// This container holds the graphs for the session detail page with a control element to show/hide different graphs
export default function GraphsContainer(props: GraphsContainerProps) {
  const [loading, setLoading] = useState(false);
  const [selectedGraphs, setSelectedGraphs] = useState<string[]>(
    // if graph.default is true, add it to the selectedGraphs
    createGraphsArray()
      .filter((graph) => graph.default)
      .map((graph) => graph.id)
    // createGraphsArray().map((graph) => graph.id)
    // []
  );
  const [plotData, setPlotData] = useState<any>(null); // TODO: replace with actual type

  async function fetchPlotData(recording_id: string) {
    try {
      const response = await requestWithAuthorization(
        process.env.REACT_APP_API_HOSTNAME +
          "/recordings/" +
          recording_id +
          "/plotdata"
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchPlotData(props.sessionId)
      .then((result) => {
        setPlotData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.sessionId]);

  function handleGraphSelectionChange(graphId: string) {
    if (selectedGraphs.includes(graphId)) {
      setSelectedGraphs(selectedGraphs.filter((id) => id !== graphId));
    } else {
      setSelectedGraphs([...selectedGraphs, graphId]);
    }
  }

  const filterControl = (
    <>
      {createGraphsArray().map((graph) => (
        <FilterCheckbox
          key={graph.id}
          controlId={graph.id}
          label={graph.name}
          checked={selectedGraphs.includes(graph.id)}
          onChange={() => handleGraphSelectionChange(graph.id)}
        />
      ))}
    </>
  );

  const filterContent = (
    <Container className="filter-drop-container py-2 px-3">
      <Row>
        <Col>
          <span className="filter-drop-heading">Filters</span>
        </Col>
        <Col className="d-flex justify-content-end">
          <a
            onClick={() =>
              setSelectedGraphs(createGraphsArray().map((graph) => graph.id))
            }
            className="wind-link"
            style={{ textDecoration: "none" }}
          >
            Select All
          </a>
        </Col>
      </Row>
      <Row className="divider my-3 mx-0" />
      <Row className="py-2">
        <Col>
          <span className="filter-drop-subheading">Graphs</span>
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <ScrollableContainer content={filterControl} maxHeight="20vh" />
        </Col>
      </Row>
    </Container>
  );

  var content = <></>;
  if (loading) {
    content = (
      <Row>
        <Col className="d-flex justify-content-center">
          <Spinner animation="border" variant="light" />
        </Col>
      </Row>
    );
  }

  if (plotData !== undefined && plotData !== null && plotData.length === 0) {
    content = (
      <Row>
        <Col className="d-flex justify-content-center">
          <h2>No data available</h2>
        </Col>
      </Row>
    );
  }

  if (
    plotData !== undefined &&
    plotData !== null &&
    selectedGraphs.length === 0
  ) {
    content = (
      <Row>
        <Col className="d-flex justify-content-center">
          <h2>No graphs selected</h2>
        </Col>
      </Row>
    );
  }

  if (
    plotData !== undefined &&
    plotData !== null &&
    plotData.length > 0 &&
    selectedGraphs.length > 0
  ) {
    content = (
      <Stack gap={2}>
        {createGraphsArray()
          .filter((graph) => selectedGraphs.includes(graph.id))
          .map((graph) => (
            <GraphCard
              recording_id={props.sessionId}
              title={graph.name}
              id={graph.id}
              xKey={"timestamp"}
              yKey={graph.yKey}
            />
          ))}
      </Stack>
    );
  }

  return (
    <Container fluid className="px-0 pt-2">
      <Row className="pb-2">
        <Col>
          <h1>Graphs</h1>
        </Col>
        <Col className="text-end">
          <DropdownButton
            icon={<BsFilter />}
            text={"Filters"}
            dropdownContent={filterContent}
            endAlign={true}
          />
        </Col>
      </Row>
      {content}
    </Container>
  );
}

function createGraphsArray() {
  return [
    {
      id: "temp",
      name: "Temperature",
      xKey: "timestamp",
      yKey: "temp",
      default: false,
    },
    {
      id: "hum",
      name: "Humidity",
      xKey: "timestamp",
      yKey: "hum",
      default: false,
    },
    {
      id: "accel",
      name: "Accelerometer",
      xKey: "timestamp",
      yKey: ["accel_x", "accel_y", "accel_z"],
      default: false,
    },
    {
      id: "gyro",
      name: "Gyroscope",
      xKey: "timestamp",
      yKey: ["gyro_x", "gyro_y", "gyro_z"],
      default: false,
    },
    {
      id: "angle",
      name: "Angle",
      xKey: "timestamp",
      yKey: ["angle_x", "angle_y", "angle_z", "angle_w"],
      default: false,
    },
    {
      id: "position",
      name: "Position",
      xKey: "timestamp",
      yKey: ["lat", "lon", "alt"],
      default: false,
    },
    {
      id: "speed",
      name: "Speed",
      xKey: "timestamp",
      yKey: "speed",
      default: false,
    },
    {
      id: "v_speed",
      name: "Wind Speed",
      xKey: "timestamp",
      yKey: "v_speed",
      default: true,
    },
    {
      id: "alpha_deg",
      name: "Alpha [°]",
      xKey: "timestamp",
      yKey: "alpha_deg",
      default: true,
    },
    {
      id: "beta_deg",
      name: "Beta [°]",
      xKey: "timestamp",
      yKey: "beta_deg",
      default: true,
    },
    {
      id: "Tu",
      name: "Turbulence Level",
      xKey: "timestamp",
      yKey: "Tu",
      default: false,
    },
    {
      id: "unev",
      name: "Unevenness Score",
      xKey: "timestamp",
      yKey: "unev",
      default: false,
    },
    {
      id: "gyro_stab",
      name: "Stability Score",
      xKey: "timestamp",
      yKey: "gyro_stab",
      default: false,
    },
    // { id: "chi", name: "Unknown metric", xKey: "timestamp", yKey: "chi" },
  ];
}
