import { Col, Row, Spinner } from "react-bootstrap";
import Symbol from "./Symbol";
import { MdAccessAlarm } from "react-icons/md";

interface SmallCardProps {
  name: string;
  description: string;
  value: string;
  unit: string;
  symbol: React.ReactNode;
  fromWhen: string;
  loading?: boolean;
}

export default function SmallCard({
  name,
  description,
  value,
  unit,
  symbol,
  fromWhen,
  loading = false,
}: SmallCardProps) {
  if (loading === true) {
    return (
      <Row className="mx-0 background-color-light rounded-corners p-3 h-100">
        <Col xs={6}>
          <Row>
            <Col>
              <span className="card-text">{name}</span>
            </Col>
          </Row>
        </Col>
        <Row>
          <Col className="w-100 justify-content-center text-center">
            <Spinner animation="border" variant="light" />
          </Col>
        </Row>
      </Row>
    );
  }
  // component with a KPI-name, a description the value, a symbol inside a circle and from when the value is (e.g. 4 Hrs ago)
  return (
    <Row className="mx-0 background-color-light rounded-corners p-3 h-100">
      <Col xs={6}>
        <Row>
          <Col>
            <span className="card-text">{name}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="card-heading">{`${
              value !== undefined ? value : "-"
            } ${unit !== undefined ? unit : ""}`}</span>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <span
              className="card-text"
              style={{ display: "flex", alignItems: "center" }}
            >
              {<MdAccessAlarm style={{ marginRight: "5px" }} />}
              {fromWhen}
            </span>
          </Col>
        </Row>
      </Col>
      <Col xs={6}>
        {/* <Row>
                    <Col className="d-flex justify-content-end">
                        <span>{value}</span>
                    </Col>
                </Row> */}
        <Row className="align-items-center h-100">
          <Col className="d-flex justify-content-end">
            <Symbol symbol={symbol} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
