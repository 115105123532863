import { useEffect, useState } from "react";
import { fetchPlotData } from "../../../network/plotdata";

export default function useZoomableChart(
  recordingId: string,
  xKey: string,
  yKey: Array<string>
) {
  const [plotData, setPlotData] = useState<any>([]);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(0);
  const [refAreaLeft, setRefAreaLeft] = useState<string | undefined>(undefined);
  const [refAreaRight, setRefAreaRight] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchAndSetData = async () => {
      try {
        const updatedData = await fetchData();
        setPlotData(updatedData);
        setStartIndex(0);
        setEndIndex(updatedData.length);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchAndSetData();
  }, [recordingId, xKey, yKey]);

  async function fetchData(start?: string, end?: string) {
    const promises = yKey.map(
      async (key) => await fetchPlotData(recordingId, key, start, end)
    );

    const results = await Promise.all(promises);

    let updatedData = [...plotData];
    results.forEach((result) => {
      if (result.status === 200) {
        updatedData = addFetchedDataToPlotDataArray(result.data, updatedData);
      }
    });

    updatedData.sort((a: any, b: any) => a[xKey].localeCompare(b[xKey]));
    return updatedData;
  }

  function addFetchedDataToPlotDataArray(data: any, currentData: any[]) {
    const newData = [...currentData];
    data.forEach((entry: any) => {
      const index = newData.findIndex((e: any) => e[xKey] === entry[xKey]);
      if (index === -1) {
        newData.push(entry);
      } else {
        newData[index] = { ...newData[index], ...entry };
      }
    });
    return newData;
  }

  function handleMouseDown(e: any) {
    if (e) {
      setRefAreaLeft(e.activeLabel);
    }
  }

  function handleMouseMove(e: any) {
    if (e) {
      refAreaLeft && setRefAreaRight(e.activeLabel);
    }
  }

  async function zoom() {
    if (!refAreaLeft || !refAreaRight || refAreaLeft === refAreaRight) {
      setRefAreaLeft(undefined);
      setRefAreaRight(undefined);
      return;
    }

    setIsLoading(true);
    const sortedRefs = [refAreaLeft, refAreaRight].sort();
    const [newRefAreaLeft, newRefAreaRight] = sortedRefs;

    const updatedData = await fetchData(newRefAreaLeft, newRefAreaRight);
    setStartIndex(
      updatedData.findIndex((e: any) => e[xKey] === newRefAreaLeft)
    );
    setEndIndex(updatedData.findIndex((e: any) => e[xKey] === newRefAreaRight));
    setRefAreaLeft(undefined);
    setRefAreaRight(undefined);
    setPlotData(updatedData);
    setIsLoading(false);
  }

  function resetZoom() {
    setStartIndex(0);
    setEndIndex(plotData.length);
  }

  return {
    plotData: plotData.slice(startIndex, endIndex),
    handleMouseDown,
    handleMouseMove,
    zoom,
    resetZoom,
    refAreaLeft,
    refAreaRight,
    isLoading,
  };
}
