import { FaStrava } from "react-icons/fa";
import ConnectButton from "../ConnectButton";
import { requestWithAuthorization } from "../../../../network/apiRequests";
import { Image } from "react-bootstrap";

export default function StravaConnectButton({
  connected,
  access_token,
}: {
  connected: boolean;
  access_token: string;
}) {
  function connectStrava() {
    // create the URL to redirect the user to Strava's OAuth page
    const url = `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_STRAVA_REDIRECT_URI}&response_type=code&scope=read_all,activity:read_all`;
    // redirect the user to the URL
    window.open(url, "_self");
  }

  function disconnectStrava() {
    const url = `https://www.strava.com/oauth/deauthorize?access_token=${access_token}`;
    fetch(url, {
      method: "post",
    }).then(() => {
      // call windpulse API to delete the connection from the database
      const deleteUrl = `${process.env.REACT_APP_API_HOSTNAME}/user/auth?serviceName=strava`;
      requestWithAuthorization(deleteUrl, {}, "delete").then(() => {
        window.location.reload();
      });
    });
  }

  if (connected) {
    return (
      <ConnectButton
        icon=<FaStrava />
        text="Disconnect Strava"
        onClick={disconnectStrava}
        classes="wind-btn-outline"
      />
    );
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Image
        src="/img/btn_strava_connectwith_orange.png"
        alt="Connect with Strava"
        style={{ cursor: "pointer" }}
        onClick={connectStrava}
      />
    </div>
  );
}
