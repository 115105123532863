import DashboardButton from "../DashboardButton";
import BigCard from "./BigCard";
import SessionRow from "./SessionRow";
import { useNavigate } from "react-router-dom";

interface SessionCardProps {
    sessions: { id: string, name: string, duration: string, date: string }[];
}

export default function SessionCard( { sessions }: SessionCardProps) {
    let navigate = useNavigate();
    let button = <DashboardButton text="View All" onClick={() => { navigate("/sessions") }} />

    let sessionRows = sessions.map((session, index) => {
        return (<SessionRow key={index} sessionID={session?.id} sessionName={session?.name} sessionDuration={session.duration} sessionDate={session.date} lastSession={index + 1 === sessions.length} />)
    });

    let content = sessionRows;

    return (
        <BigCard heading="Sessions History" subheading="Previous Sessions" button={button} content={content} />
    )
}