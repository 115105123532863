

export function buildAcceptString( exportType: string) {
    if (exportType === "csv") {
        return "text/csv"
    }
    if (exportType === "json") {
        return "application/json"
    }
    return "*/*"
}