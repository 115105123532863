import { Container, Row, Col } from "react-bootstrap";

interface FormContainerProps {
    heading: string;
    children: React.ReactNode;
}

export default function FormContainer({ heading, children }: FormContainerProps) {
    return (
        <Container fluid className='background-color no-padding vh-100 d-flex align-items-center justify-content-center'>
            <Container fluid className='background-color' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Row className="mx-0">
                    <Col><h1 className="heading">{heading}</h1></Col>
                </Row>
                <Row className="mx-0 w-100-per">
                    <Col className="pt-5 ps-5 pe-5">{children}</Col>
                </Row>
            </Container>
        </Container>
    )
}