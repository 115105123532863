import { Col, Form, Row } from "react-bootstrap";

interface FilterCheckboxProps {
    controlId: string;
    label: React.ReactNode;
    additionalContent?: React.ReactNode;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }
  
  const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ controlId, label, additionalContent, checked, onChange }) => {
    return (
      <Form.Group controlId={controlId} as={Row} className="wind-form-check pb-1 m-0">
        <Col>
          <Form.Check type="checkbox">
            <Form.Check.Input type="checkbox" className="wind-form-checkbox transparent-background silver-border" checked={checked} onChange={onChange} />
            <Form.Check.Label className="wind-form-label color-silver" style={{ paddingTop: ".25em" }}>{label}</Form.Check.Label>
          </Form.Check>
        </Col>
        {additionalContent && (
          <Col>{additionalContent}</Col>
        )}
      </Form.Group>
    );
  };
  
  export default FilterCheckbox;