import { Container, Row, Col } from "react-bootstrap";
import RecordingTable from "../components/dashboard/RecordingTable";
import { requestWithAuthorization } from "../network/apiRequests";
import { useEffect, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { Recording } from "../components/dashboard/interfaces/Recording";
import { currentUserIsAdmin } from "../firebase";
import CometSpinner from "../components/CometSpinner";

export default function RecordingsPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [recordingData, setRecordingData] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  async function fetchRecordings({ userId }: { userId: string }) {
    try {
      const recordingsResponse = await requestWithAuthorization(
        `${process.env.REACT_APP_API_HOSTNAME}/${userId}/recordings`
      );
      if (recordingsResponse.status !== 200) {
        redirect("/401");
        return;
      }
      setRecordingData(recordingsResponse.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!userId) {
      // redirect to 404 page
      navigate("/404");
      return;
    }
    fetchRecordings({ userId: userId ?? "" });
    currentUserIsAdmin().then((isAdmin) => {
      setIsAdmin(isAdmin);
    });
  }, [userId, navigate]);

  const spinnerContainer = (
    <Container
      style={{
        backgroundColor: "#091014",
        minHeight: "100vh",
        minWidth: "100vw",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CometSpinner height={100} />
    </Container>
  );

  if (recordingData === null) {
    return spinnerContainer;
  }

  return (
    <Container
      fluid
      style={{ backgroundColor: "#091014", minHeight: "100vh", color: "#fff" }}
    >
      <Row className="pb-3 pt-3 text-center">
        <Col>
          <h1>Recordings</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <RecordingTable
            userId={userId}
            recordings={recordingData as Recording[]}
            onBack={() => {
              navigate(-1);
            }}
            isAdmin={isAdmin}
          />
        </Col>
      </Row>
    </Container>
  );
}
