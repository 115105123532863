import { Row, Col } from "react-bootstrap";


interface NoHeadingCardProps {
    content: React.ReactNode;
}

export default function NoHeadingCard({ content }: NoHeadingCardProps) {
    return (
        <Row className="mx-0 background-color-light rounded-corners p-3">
            <Col className="px-0">
                <Row className="pt-5">
                    <Col>{content}</Col>
                </Row>
            </Col>
        </Row>
    )


}