import BigCard from "./BigCard";
import { Col, Row, Spinner } from "react-bootstrap";
import KPISection from "./KPISection";
import DashboardDropdown from "../DashboardDropdown";
import { useNavigate } from "react-router-dom";

interface KPICardProps {
  sessionID: string;
  heading: string;
  subheading?: string;
  kpis: {
    name: string;
    value: string;
    unit: string;
    symbol: React.ReactNode;
  }[];
  showButton?: boolean;
  loading?: boolean;
}

export default function KPICard({
  sessionID,
  heading,
  subheading,
  kpis,
  showButton,
  loading,
}: KPICardProps) {
  let navigate = useNavigate();

  let dropdownItems = [
    {
      text: "View Details",
      onClick: () => {
        navigate("/sessions/" + sessionID);
      },
    },
    {
      text: "Download CSV",
      onClick: () => {
        console.log("Forward to download CSV");
      },
    },
    {
      text: "Download JSON",
      onClick: () => {
        console.log("Forward to download JSON");
      },
    },
  ];
  let kpiCols = null;
  if (kpis.length === 0) {
    kpiCols = <h1>No Metrics found</h1>;
  } else {
    kpiCols = kpis.map((kpi, index) => {
      return (
        <Col>
          <KPISection
            key={index}
            name={kpi.name !== undefined ? kpi.name : ""}
            value={kpi.value !== undefined ? kpi.value : "-"}
            unit={kpi.unit !== undefined ? kpi.unit : ""}
            symbol={kpi.symbol !== undefined ? kpi.symbol : <></>}
          />
        </Col>
      );
    });
  }

  let content = <Row>{kpiCols}</Row>;

  let button = <></>;
  if (showButton !== undefined && showButton === true) {
    button = <DashboardDropdown text={heading} items={dropdownItems} />;
  }

  return (
    <BigCard
      heading={heading}
      subheading={subheading}
      button={button}
      content={content}
      loading={loading}
    />
  );
}
