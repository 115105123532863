import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { requestWithAuthorization } from "../../../../../network/apiRequests";

export default function StravaCallback() {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  console.warn("URL Params: ", urlParams);
  const error = urlParams.get("error");
  var content;
  if (error) {
    setErrorMsg(
      "An error occurred during the authentication process. Please try again later."
    );
  }
  const scope = urlParams.get("scope");

  if (
    scope &&
    !scope.includes("read_all") &&
    !scope.includes("activity:read_all")
  ) {
    setErrorMsg(
      "The requested scope is not correct. Please make sure you have granted the required permissions."
    );
  }
  useEffect(() => {
    setCode(urlParams.get("code"));
  }, []);

  useEffect(() => {
    if (code) {
      authenticateStrava(code);
    }
  }, [code]);

  async function authenticateStrava(code: string) {
    const url = `${process.env.REACT_APP_API_HOSTNAME}/user/auth?serviceName=strava&code=${code}&clientId=${process.env.REACT_APP_STRAVA_CLIENT_ID}`;
    try {
      const response = await requestWithAuthorization(url, {}, "post");
      if (response.status === 200) {
        console.log("Data: ", response.data);
      } else {
        setErrorMsg(
          "An error occurred during the authentication process. Please try again later."
        );
      }
    } catch (error: any) {
      setErrorMsg(error.message);
    }
  }

  if (errorMsg) {
    content = (
      <>
        <h1 style={{ color: "#FF3282" }}>
          <FaCheckCircle />
        </h1>
        <h1>Authentication Unsuccessful</h1>
        <p>{errorMsg}</p>
        <Link to="/settings">Back to Connections</Link>
      </>
    );
  } else {
    content = (
      <>
        <h1 className="light-primary">
          <FaCheckCircle />
        </h1>
        <h1>Authentication Successful</h1>
        <p className="text-white">
          You have successfully connected your Strava account.
        </p>
        <Link to="/settings">Back to Connections</Link>
      </>
    );
  }

  return (
    <Container fluid className="text-center justify-content-center pt-5">
      {content}
    </Container>
  );
}
