import BigCard from "../../dashboard/card/BigCard";
import LineChartComponent from "../../plots/LineChartComponent";
import AreaChartComponent from "../../plots/AreaChartComponent";
import { Container, Image } from "react-bootstrap";

interface GraphCardProps {
  recording_id: string;
  title: string;
  id: string;
  bigHeading?: boolean;
  xKey?: string;
  yKey?: string | Array<string>;
}

export default function GraphCard(props: GraphCardProps) {
  function renderChart(
    xKey: string | undefined,
    yKey: string | Array<string> | undefined
  ) {
    if (xKey === undefined || yKey === undefined) {
      return <p>Invalid keys specified</p>;
    }
    if (Array.isArray(yKey)) {
      return (
        <LineChartComponent
          recordingId={props.recording_id}
          xKey={xKey}
          yKey={yKey}
        />
      );
    } else {
      return (
        <AreaChartComponent
          recordingId={props.recording_id}
          xKey={xKey}
          yKey={[yKey]}
        />
      );
    }
  }

  return (
    <BigCard
      heading={props.title}
      key={props.title}
      button={
        <Image
          src="/img/api_logo_pwrdBy_strava_stack_gray.png"
          height={"50rem"}
        />
      }
      content={
        <Container
          className="mx-0 px-0"
          style={{ height: "30vh", width: "100%" }}
        >
          {renderChart(props.xKey, props.yKey)}
        </Container>
      }
      subheading=""
      bigHeading={props.bigHeading}
    />
  );
}
