

export function getTimePassed(endDateTime: string): string {
    // Convert the endDateTime string to a Date object
    const endDate = new Date(endDateTime);

    // Get the current date and time
    const now = new Date();

    // Calculate the time difference in milliseconds
    const diffMs = now.getTime() - endDate.getTime();

    // Convert the time difference to minutes, hours, and days
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    // Return the time difference in the appropriate unit
    if (diffDays > 0) {
        return `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;
    } else if (diffHours > 0) {
        return `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'} ago`;
    } else {
        return `${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'} ago`;
    }
}

export function getTimeDifference(startDateTime: string, endDateTime: string): string {
    // Convert the startDateTime and endDateTime strings to Date objects
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);

    // Calculate the time difference in milliseconds
    const diffMs = endDate.getTime() - startDate.getTime();

    // Convert the time difference to hours and minutes
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs / (1000 * 60)) % 60);

    // Return the time difference in the format "X h Y min"
    return `${diffHours} h ${diffMinutes} min`;
}