import { useState } from "react";
import { Container, Form } from "react-bootstrap";
import FormField from "../forms/FormField";
import FormButton from "../forms/FormButton";
import FormSwitchHint from "../forms/FormSwitchHint";
import { passwordReset } from "../../firebase";
import FormMessageField from "../forms/FormMessageField";


export default function ForgotPasswordForm() {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        setMessage(null);
        
        try {
            if (!email) {
                setError("Email field is empty. Please enter your email.");
                return
            }

            await passwordReset(email);
            setMessage("Password reset email sent successfully. Please check your inbox.");
        } catch (error:any) {
            if (error.code === "auth/user-not-found") {
                setError("No user found with this email. Please enter a valid email.");
            } else {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Container fluid className="background-color" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Form className="w-100-per">
                <FormField controlId='formBasicEmail' label='Email address' type='email' placeholder='Enter your email' value={email} onChange={handleEmailChange} />
                <FormMessageField message={message} error={error} />
                <FormButton type="submit" text="Reset password" onClick={handleSubmit} isLoading={isLoading} />
            </Form>
            <FormSwitchHint question="Remember your password?" linkText="Sign in" linkTo="/login" />
        </Container>
    )
}