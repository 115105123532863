import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollableContainer from "../../ScrollableContainer";
import FormCheckbox from "../../forms/FormCheckbox";
import DistanceFilter from "./DistanceFilter";
import FatigueFilter from "./FatigueFilter";


interface FiltersDropdownProps {
    
}


export default function FiltersDropdown() {

    return (
        <Container className="filter-drop-container py-2 px-3">
            <Row>
                <Col><span className="filter-drop-heading">Filters</span></Col>
                <Col className="d-flex justify-content-end"><a onClick={() => console.log("Reset Filter")} className="wind-link" style={{textDecoration: "none"}}>Clear All</a></Col>
            </Row>
            <Row className="divider my-3 mx-0" />
            <Row className="py-2">
                <Col>
                    <span className="filter-drop-subheading">Length of recording</span>
                </Col>
            </Row>
            <Row className="py-2">
                <Col>
                    <ScrollableContainer content={<DistanceFilter />} maxHeight="20vh"/>
                </Col>
            </Row>
            <Row className="divider my-3 mx-0" />
            <Row className="py-2">
                <Col>
                    <span className="filter-drop-subheading">Amount of fatigue</span>
                </Col>
            </Row>
            <Row className="py-2">
                <Col>
                    <ScrollableContainer content={<FatigueFilter />} maxHeight="20vh"/>
                </Col>
            </Row>
            
        </Container>
    )
}