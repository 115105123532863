import TwoColumns from "../components/TwoColumns";
import SignupImage from "../components/signup/SignupImage";
import SignupFormContainer from "../components/signup/SignupFormContainer";


export default function SignupPage() {
    const leftContent= (
        <SignupImage />
    )
    const rightContent = (
        <SignupFormContainer />
    )
    return(
        <TwoColumns leftContent={leftContent} rightContent={rightContent} />
    )
}