import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import CustomNavbar from "../components/navigation/navbar/CustomNavbar";
import { Button, Col, Container, Row } from "react-bootstrap";
import WindpulsSidebar from "../components/navigation/sidebar/WindpulsSidebar";

const RootLayout = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container fluid className="px-0 d-flex flex-column min-vh-100">
      <Row noGutters className="flex-grow-1 background-color">
        <Col
          xs={2}
          md={2}
          className="d-none d-md-flex background-color-light topline sidebar pe-0"
        >
          {/* Sidebar for larger screens */}
          <WindpulsSidebar />
        </Col>
        <Col xs={12} md={10} className="px-0">
          {/* Hamburger menu button for smaller screens */}
          <Button
            className="d-md-none dropdown-btn text-white ms-4 mt-3"
            variant="light"
            onClick={() => setIsOpen(!isOpen)}
          >
            ☰
          </Button>
          {/* Content for smaller screens */}
          {isOpen && (
            <div className="d-md-none">
              <WindpulsSidebar />
            </div>
          )}
          {/* Main content */}
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default RootLayout;
