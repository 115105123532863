import { formatUTCTimestamp } from "../../utils/DateFormatter";

export default function CustomTooltip({ active, payload, label }: any) {
  function formatValue(value: number) {
    return value.toFixed(2);
  }

  if (active && payload && payload.length) {
    // If the label is a timestamp (UTC format), convert it to local time and remove the date
    if (label.includes("T")) {
      label = formatUTCTimestamp(label);
    }
    return (
      <div className="custom-tooltip background-color silver-border">
        <p className="tooltip-label">{`${label}`}</p>
        {payload.map((item: any, index: number) => (
          <p
            key={`item-${index}`}
            className="tooltip-value"
            style={{ color: item.color }}
          >{`${item.name}: ${formatValue(item.value)}`}</p>
        ))}
      </div>
    );
  }

  return null;
}
