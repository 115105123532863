import { requestWithAuthorization } from "./apiRequests";

interface FetchDataForZoomResult {
  status: number;
  data?: any;
  error?: any;
}

export async function fetchPlotData(
  recordingId: string,
  characteristic: string,
  start?: string,
  end?: string
): Promise<FetchDataForZoomResult> {
  let requestUrl = `${process.env.REACT_APP_API_HOSTNAME}/recordings/${recordingId}/plotdata?characteristic=${characteristic}`;
  if (start !== undefined) {
    requestUrl = requestUrl + `&start=${start}`;
  }
  if (end !== undefined) {
    requestUrl = requestUrl + `&end=${end}`;
  }
  try {
    const response = await requestWithAuthorization(requestUrl);
    if (response.status === 200) {
      return { status: response.status, data: response.data };
    } else {
      console.error(response);
      return { status: response.status, error: response.statusText };
    }
  } catch (error) {
    console.error(error);
    return { status: 500, error }; // Generic status code
  }
}
