import { Container, Row, Col, Button } from "react-bootstrap"

export default function Welcome() {
    return(
        <Container fluid style={{ backgroundColor: '#091014', minHeight: '100vh', color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Row>
                <Col className="text-center">
                    <h1>Get the Wind, get better!</h1>
                    <p>
                    Welcome to the world of windpuls.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='text-nowrap'>
                    <Button variant='light' size="lg" href='https://windpuls.com'>Get Started</Button>
                </Col>
                <Col className='text-nowrap'>
                    <Button variant='outline-light' size="lg" href='/login'>Login</Button>
                </Col>
            </Row>
        </Container>
    )
}