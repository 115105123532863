import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendPasswordResetEmail,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  updateProfile,
  browserSessionPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDFmrnc6rYeeW7J4NJGbDvz47t23yZzXAo",
  authDomain: "windpuls-auth.firebaseapp.com",
  databaseURL:
    "https://windpuls-auth-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "windpuls-auth",
  storageBucket: "windpuls-auth.appspot.com",
  messagingSenderId: "819058730095",
  appId: "1:819058730095:web:802dd9966473bb7092599a",
  measurementId: "G-6W1B7M3BHX",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;

export async function currentUserIsAdmin() {
  // Force token refresh
  await auth.currentUser?.getIdToken();
  const idTokenResult = await auth.currentUser?.getIdTokenResult();
  if (
    idTokenResult &&
    idTokenResult.claims &&
    idTokenResult.claims.role?.toLowerCase() === "admin"
  ) {
    return true;
  }
  return false;
}

export const passwordReset = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
};

export const confirmThePasswordReset = async (
  code: string,
  newPassword: string
) => {
  if (!code && !newPassword) return;
  return await confirmPasswordReset(auth, code, newPassword);
};

export const signUp = async (email: string, password: string) => {
  if (!email && !password) return;
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const updateName = async (firstname: string, lastname: string) => {
  if (!firstname || !lastname) return;
  const currentUser = auth.currentUser;
  if (!currentUser) return;
  return await updateProfile(currentUser, {
    displayName: firstname + " " + lastname,
  });
};
