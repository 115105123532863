import { ChangeEvent, useState } from "react";
import { Form, Container } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { auth, currentUserIsAdmin } from "../../firebase";
import {
  browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  loginUserError,
  loginUserSuccess,
  setIsLoading,
} from "../../auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import FormField from "../forms/FormField";
import FormCheckbox from "../forms/FormCheckbox";
import FormButton from "../forms/FormButton";
import FormSwitchHint from "../forms/FormSwitchHint";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector((state) => state.auth.isLoading);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  const navigate = useNavigate();

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch(setIsLoading(true));
    let persistence = browserSessionPersistence;
    if (rememberMe) {
      persistence = browserLocalPersistence;
    }
    setPersistence(auth, persistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // signed in
            const user = userCredential.user;
            const token = await user.getIdToken();

            const cookieUrl = `${process.env.REACT_APP_API_HOSTNAME}/user/login`;
            // Send the ID token to the backend to create the cookie
            const sessionResponse = await fetch(cookieUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({ token: token }),
              credentials: "include",
            });

            dispatch(loginUserSuccess(token));
            navigate("/dashboard");
          })
          .catch((error) => {
            const errorMessage = error.message;
            dispatch(loginUserError(errorMessage));
          })
          .finally(() => {
            dispatch(setIsLoading(false));
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        dispatch(loginUserError(errorMessage));
      });
  };

  return (
    <Container
      fluid
      className="background-color"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form className="w-100-per">
        <FormField
          controlId="formBasicEmail"
          label="Email address"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
        />
        <FormField
          controlId="formBasicPassword"
          label="Password"
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={handlePasswordChange}
        />
        <FormCheckbox
          controlId="formBasicCheckbox"
          label={"Remember Me"}
          checked={rememberMe}
          onChange={handleRememberMeChange}
          additionalContent={
            <Link to="/forgot-password" className="wind-link float-end">
              Forgot Password?
            </Link>
          }
        />
        <FormButton
          type="submit"
          text="Sign in"
          isLoading={isLoading}
          onClick={handleSubmit}
        />
      </Form>
      <FormSwitchHint
        question="Don't have an account?"
        linkText="Sign Up"
        linkTo="/signup"
      />
    </Container>
  );
}
