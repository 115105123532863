export function formatDate(dateString: string): string {
  // Convert the dateString to a Date object
  const date = new Date(dateString);

  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Return the date in the format "30 Jun 2023"
  return `${date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
}

export function formatUTCTimestamp(timestamp: string): string {
  // Convert the timestamp to a Date object
  const date = new Date(timestamp);

  // Format the time in UTC
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

  // Return the formatted time
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}
