import { Dropdown } from "react-bootstrap";

interface DashboardDropdownProps {
    text: string;
    items: Array<{ text: string, onClick: () => void }>;
}

export default function DashboardDropdown({ text, items }: DashboardDropdownProps) {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" className="wind-btn wind-btn-small px-3">
                {text}
            </Dropdown.Toggle>
            <Dropdown.Menu align={'end'} className="background-color-light">
                {items.map((item, index) => (
                    <Dropdown.Item key={index} onClick={item.onClick}>{item.text}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}