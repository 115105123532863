import { useState } from "react";
import FilterCheckbox from "./FilterCheckbox";
import { Col, Row } from "react-bootstrap";


export default function DistanceFilter() {
    // Add a state variable to track the checked state of each checkbox
  const [checkedStates, setCheckedStates] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedStates({
      ...checkedStates,
      [event.target.id]: event.target.checked,
    });
  };

  const distanceRanges = [
    '0-10 km',
    '10-20 km',
    '20-30 km',
    '30-40 km',
    '40-50 km',
    '50-60 km',
    '60-70 km',
    '70-80 km',
    '80-90 km',
    '90-100 km',
    '> 100 km',
  ];
  
  const distanceFilterList = distanceRanges.map((range) => (
      <FilterCheckbox
      key={range}
      controlId={range}
      label={range}
      checked={!!checkedStates[range]}
      onChange={handleCheckboxChange}
      />
  ));

  return (
    <div>
      {distanceFilterList}
    </div>
  );
}