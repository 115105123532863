import { Col, Row } from "react-bootstrap";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

interface SessionPaginationProps {
    activePage: number;
    totalSessions: number;
    sessionsPerPage: number;
}

export default function SessionPagination( { activePage, totalSessions, sessionsPerPage }: SessionPaginationProps) {

    const MAX_PAGES = 5;

    const totalPages = Math.ceil(totalSessions / sessionsPerPage);

    const pages = [];

    for(let i = 1; i <= totalPages; i++) {
        let page;
        if (i == activePage) {
            page = <Col className="session-pagination-active me-4">{i}</Col>
        } else {
            page = <Col className="session-pagination me-4">{i}</Col>
        }
        pages.push(page);
    }

    return (
        <Row className="d-flex align-items-center">
            <Col className="session-pagination-arrow pe-4"><AiOutlineLeft /></Col>
            {pages}
            <Col className="session-pagination-arrow"><AiOutlineRight /></Col>
        </Row>
    )
}