import TwoColumns from "../components/TwoColumns";
import ForgotPasswordFormContainer from "../components/forgot-password/ForgotPasswordFormContainer";
import ForgotPasswordImage from "../components/forgot-password/ForgotPasswordImage";


export default function ForgotPasswordPage() {
    const leftContent= (
        <ForgotPasswordImage />
    )
    const rightContent = (
        <ForgotPasswordFormContainer />
    )

    return(
        <TwoColumns leftContent={leftContent} rightContent={rightContent} />
    )
}