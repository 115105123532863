import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

function Calendar() {
    const [currentDate, setCurrentDate] = useState(new Date());

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const getFirstDayOfMonth = () => {
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        return firstDay === 0 ? 6 : firstDay - 1;
    };

    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const days = Array.from({length: daysInMonth}, (_, i) => ({ day: i + 1, currMonth: true }));

    // Add days for the start of the month from the previous month
    const daysInLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
    const placeholdersStart = Array.from({length: getFirstDayOfMonth()}, (_, i) => ({ day: daysInLastMonth - i, currMonth: false })).reverse();

    // Add days for the end of the month from the next month
    const placeholdersEnd = Array.from({length: 42 - daysInMonth - placeholdersStart.length}, (_, i) => ({ day: i + 1, currMonth: false }));

    // Combine all days
    const allDays = [...placeholdersStart, ...days, ...placeholdersEnd];

    // Break down the calendar days into weeks
    const weeks = [];
    for (let i = 0; i < allDays.length; i += 7) {
        weeks.push(allDays.slice(i, i + 7));
    }

    return (
        <Container className="calendar-container">
            <Row className="justify-content-center align-items-center mb-4">
                <Col xs="auto">
                    <AiOutlineLeft />
                    {/* <Button onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))}>Prev</Button> */}
                </Col>
                <Col xs="auto">
                    <span className='calendar-header'>{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</span>
                </Col>
                <Col xs="auto">
                    <AiOutlineRight />
                    {/* <Button onClick={() => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))}>Next</Button> */}
                </Col>
            </Row>
            {weeks.map((week, index) => (
                <Row key={index} className='text-center'>
                    {week.map((day, index) => (
                        <Col key={index} className="p-2 calendar-day" style={{color: day.currMonth ? 'white' : 'grey'}}>
                            {day.day}
                        </Col>
                    ))}
                </Row>
            ))}
        </Container>
    );
}

export default Calendar;
