import { Col, Image, Row, Spinner } from "react-bootstrap";
import DashboardButton from "../DashboardButton";
import BigCard from "./BigCard";
import CustomMapContainer from "../../map/CustomMapContainer";
import { useEffect, useState } from "react";
import { GPSCoorindate } from "../../../models/GPSCoorindate";
import { requestWithAuthorization } from "../../../network/apiRequests";

interface MapCardProps {
  sessionID?: string;
}

export default function MapCard({ sessionID }: MapCardProps) {
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState([] as GPSCoorindate[]);

  // function to fetch the coordinates of a session from the backend
  async function fetchCoordinates(sessionID: string) {
    try {
      const response = await requestWithAuthorization(
        process.env.REACT_APP_API_HOSTNAME + "/recordings/" + sessionID + "/gps"
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (sessionID === undefined || sessionID === null) {
      return;
    }
    setLoading(true);
    fetchCoordinates(sessionID)
      .then((result) => {
        setCoordinates(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sessionID]);

  if (loading) {
    return (
      <BigCard
        heading="Map Route"
        content={
          <Row className="w-100 justify-content-center text-center">
            <Spinner variant="light" animation="border" />
          </Row>
        }
      />
    );
  }

  let button = (
    <DashboardButton
      text="View"
      onClick={() => {
        console.log("Hi from view!");
      }}
    />
  );
  var content = <></>;
  if (
    coordinates === undefined ||
    coordinates === null ||
    coordinates.length === 0
  ) {
    content = (
      <Row className="w-100 justify-content-center text-center">
        <p>No coordinates available</p>
      </Row>
    );
  } else {
    content = <CustomMapContainer points={coordinates} height="20vh" />;
  }

  return (
    <BigCard
      heading="Map Route"
      subheading="Last Recording"
      button={button}
      content={content}
    />
  );
}
