import { Outlet, Navigate } from "react-router-dom";
import { monitorAuthState } from "./auth/authCheck";
import { useAppDispatch } from "./hooks";
import { auth } from "./firebase";
import { useEffect, useState } from "react";

export default function AuthenticatedRoutes() {
  const dispatch = useAppDispatch();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          monitorAuthState(token, dispatch);
          setIsAuthenticated(true);
          setIsCheckingAuth(false);
        });
      } else {
        setIsAuthenticated(false);
        setIsCheckingAuth(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (isCheckingAuth) {
    // TODO: implement a page that shows a spinner while the app is checking the authentication state
    return <div>Checking authentication...</div>;
  }

  if (isAuthenticated) {
    return <Outlet />;
  }
  // const user = auth.currentUser;
  // console.log("user", user);
  // if (user) {
  //   user.getIdToken().then((token) => monitorAuthState(token, dispatch));
  //   return <Outlet />;
  // }
  return <Navigate to="/login" />;
}
