import axios from "axios";
import { auth } from "../firebase";

interface RequestOptions {
  headers?: { [key: string]: string };
  [key: string]: any;
}

export async function requestWithAuthorization(
  url: string,
  options: RequestOptions = {},
  requestType: "get" | "post" | "put" | "delete" = "get",
  data?: any
) {
  const token = await auth.currentUser?.getIdToken();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(options.headers || {}), // Spread the headers object if it exists
  };

  // Remove the headers property from the options object to avoid duplicating it in the request
  const { headers: _headers, ...otherOptions } = options;
  const responseType = options.responseType || "json";

  if (requestType === "get") {
    return axios.get(url, {
      headers: headers,
      responseType: responseType,
      ...otherOptions, // Spread any remaining options into the request
    });
  } else if (requestType === "post") {
    return axios.post(url, data || {}, {
      headers: headers,
      responseType: responseType,
      ...otherOptions,
    });
  } else if (requestType === "put") {
    return axios.put(url, data || {}, {
      headers: headers,
      responseType: responseType,
      ...otherOptions,
    });
  } else if (requestType === "delete") {
    return axios.delete(url, {
      headers: headers,
      responseType: responseType,
      ...otherOptions,
    });
  }

  return axios.get(url, {
    headers: headers,
    responseType: responseType,
    ...otherOptions, // Spread any remaining options into the request
  });
}

export async function requestWithCookie(
  url: string,
  options: RequestOptions = {},
  requestType: "get" | "post" | "put" | "delete" = "get"
) {
  const headers = {
    "Content-Type": "application/json",
    ...(options.headers || {}),
  };

  const { headers: _headers, ...otherOptions } = options;
  const responseType = options.responseType || "json";

  if (requestType === "get") {
    return axios.get(url, {
      withCredentials: true,
      headers: headers,
      responseType: responseType,
      ...otherOptions,
    });
  } else if (requestType === "post") {
    return axios.post(
      url,
      {},
      {
        withCredentials: true,
        headers: headers,
        responseType: responseType,
        ...otherOptions,
      }
    );
  } else if (requestType === "put") {
    return axios.put(
      url,
      {},
      {
        withCredentials: true,
        headers: headers,
        responseType: responseType,
        ...otherOptions,
      }
    );
  } else if (requestType === "delete") {
    return axios.delete(url, {
      withCredentials: true,
      headers: headers,
      responseType: responseType,
      ...otherOptions,
    });
  }

  return axios.get(url, {
    withCredentials: true,
    headers: headers,
    responseType: responseType,
    ...otherOptions,
  });
}
