

interface ScrollableContainerProps {
    content: JSX.Element,
    maxHeight?: string
}

export default function ScrollableContainer({content, maxHeight}: ScrollableContainerProps) {
    return (
        <div className="silver-border pe-2">
            <div className="scrollable-container" style={{maxHeight: maxHeight}}>
                {content}
            </div>
        </div>
    )
}