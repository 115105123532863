import { Col, Modal, Row, Spinner } from "react-bootstrap";
import DashboardButton from "../../dashboard/DashboardButton";
import { FaUpload } from "react-icons/fa";
import DropzoneComponent from "../../forms/DropzoneComponent";
import { useEffect, useState } from "react";
import IconTile from "../../IconTile";
import { IoIosFitness } from "react-icons/io";
import { requestWithAuthorization } from "../../../network/apiRequests";

export default function UploadFITFileButton() {
  const [isUploading, setIsUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [filesContainer, setFilesContainer] = useState<JSX.Element | null>(
    null
  );

  function handleDroppedFiles(files: File[]) {
    setUploadedFiles(files);
    console.log("Uploaded files:", files);
  }

  async function handleFitFileUpload() {
    setIsUploading(true);
    const recordingId = window.location.pathname.split("/").pop();
    const activityId = 1;
    const formData = new FormData();
    formData.append("file", uploadedFiles[0]);
    const url = `${process.env.REACT_APP_API_HOSTNAME}/recordings/${recordingId}/link?serviceName=fitupload&activityId=${activityId}`;
    try {
      const response = await requestWithAuthorization(
        url,
        {},
        "post",
        formData
      );
      if (response.status === 200) {
        alert("FIT file uploaded successfully!");
      } else {
        alert(`Failed to upload FIT file.\nError: ${response.data}`);
        console.error(`Failed to upload FIT file: ${response.data}`);
      }
    } catch (error) {
      alert(`Failed to upload FIT file.\nPlease try again later.`);
      console.error("Error uploading FIT file:", error);
    }
    setUploadedFiles([]);
    setShowModal(false);
    setIsUploading(false);
  }

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      setFilesContainer(null);
      return;
    }
    setFilesContainer(
      <Row>
        <Col>
          <IconTile icon={<IoIosFitness />} text={uploadedFiles[0]?.name} />
        </Col>
      </Row>
    );
  }, [uploadedFiles]);

  return (
    <>
      <DashboardButton
        icon={<FaUpload />}
        text="Upload FIT File"
        onClick={() => setShowModal(true)}
      />
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        className=""
      >
        <Modal.Header className="background-color">
          <Modal.Title className="heading">Upload FIT File</Modal.Title>
        </Modal.Header>
        <Modal.Body className="background-color text-white">
          <DropzoneComponent onFileUpload={handleDroppedFiles} />
          {filesContainer}
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#111827" }}>
          <DashboardButton
            icon={<FaUpload />}
            text={isUploading ? "Uploading..." : "Upload"}
            onClick={handleFitFileUpload}
            disabled={isUploading || uploadedFiles.length === 0}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
