import { Nav, Image } from "react-bootstrap";
import SidebarLink from "./SidebarLink";

import { AiOutlineDashboard } from "react-icons/ai";
import { LuClipboardList } from "react-icons/lu";
import { BiHelpCircle } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { BsGear } from "react-icons/bs";
import { auth, currentUserIsAdmin } from "../../../firebase";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function WindpulsSidebar() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        currentUserIsAdmin().then((result) => {
          setIsAdmin(result);
        });
      } else {
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  let adminPages = [<></>];
  if (isAdmin) {
    adminPages = [
      <div className="sidebar-divider me-3 ms-3"></div>,
      <SidebarLink link="/users" symbol={<FaUsers />} text="Users" />,
    ];
  }

  return (
    <Nav className="flex-column w-100">
      <Link to={"/dashboard"} className="text-center">
        <Image
          src="/img/windpuls_logo.svg"
          className="px-5 pt-4 pb-2 sidebar-logo-image"
        />
      </Link>
      <SidebarLink
        link="/dashboard"
        symbol={<AiOutlineDashboard />}
        text="Dashboard"
      />
      <SidebarLink
        link="/sessions"
        symbol={<LuClipboardList />}
        text="Sessions"
      />
      <SidebarLink link="/help" symbol={<BiHelpCircle />} text="Help" />
      <div className="sidebar-divider me-3 ms-3"></div>
      <SidebarLink link="/settings" symbol={<BsGear />} text="Settings" />
      {adminPages}
    </Nav>
  );
}
