import React, { useEffect, useState } from "react";
import { requestWithAuthorization } from "../../../network/apiRequests";
import StravaConnectButton from "./strava/StravaConnectButton";

interface Connection {
  id: string;
  token: string;
}

export default function ConnectionContainer() {
  const [connections, setConnections] = useState<Connection[]>([]);
  // Send a request to the API to check if the user has linked any accounts already
  // Strava, Garmin, etc.
  useEffect(() => {
    fetchConnections();
  }, []);

  const services = [{ id: "strava", component: StravaConnectButton }];

  function fetchConnections() {
    for (const service of services) {
      const url = `${process.env.REACT_APP_API_HOSTNAME}/user/auth?serviceName=${service.id}`;
      requestWithAuthorization(url)
        .then((response) => {
          if (response.data) {
            const connection: Connection = {
              id: service.id,
              token: response.data.access_token,
            };
            setConnections((prevConnections) => [
              ...prevConnections,
              connection,
            ]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  useEffect(() => {
    console.log(connections);
  }, [connections]);

  function renderConnectionButtons() {
    return services.map((service) => {
      const connection = connections.find((c) => c.id === service.id);
      if (connection) {
        return (
          <div key={service.id}>
            <service.component
              connected={true}
              access_token={connection.token}
            />
          </div>
        );
      }
      return (
        <div key={service.id}>
          <service.component connected={false} access_token="" />
        </div>
      );
    });
  }

  return (
    <div>
      <h1>Connections</h1>
      {renderConnectionButtons()}
    </div>
  );
}
