import { GiPathDistance } from "react-icons/gi";
import { MdSpeed } from "react-icons/md";
import { PiClockClockwiseFill, PiMountains } from "react-icons/pi";

interface InfoEntry {
  value: string;
  unit: string;
}

export function createKPIArrayFromSessionInfos(sessionInfos: {
  [key: string]: InfoEntry | undefined;
}) {
  const kpiArray = [];
  if (sessionInfos?.distance) {
    kpiArray.push(
      createKPI(
        "Distance",
        convert_meters_to_kilometers(parseFloat(sessionInfos.distance.value)),
        determine_distance_unit(parseFloat(sessionInfos.distance.value)),
        <GiPathDistance />
      )
    );
  }
  if (sessionInfos?.duration) {
    kpiArray.push(
      createKPI(
        "Duration",
        convert_seconds_to_hhmmss(parseInt(sessionInfos.duration.value)),
        "",
        <PiClockClockwiseFill />
      )
    );
  }
  if (sessionInfos?.max_speed) {
    kpiArray.push(
      createKPI(
        "Top Speed",
        sessionInfos.max_speed.value,
        sessionInfos.max_speed.unit,
        <MdSpeed />
      )
    );
  }
  if (sessionInfos?.avg_altitude) {
    kpiArray.push(
      createKPI(
        "Average Elevation",
        sessionInfos.avg_altitude.value,
        sessionInfos.avg_altitude.unit,
        <PiMountains />
      )
    );
  }
  return kpiArray;
}

function createKPI(
  name: string,
  value: string,
  unit: string,
  icon: React.ReactNode
) {
  return {
    name: name,
    value: value,
    unit: unit,
    symbol: icon,
  };
}

function convert_seconds_to_hhmmss(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours}h ${minutes}m ${remainingSeconds}s`;
}

function convert_meters_to_kilometers(meters: number) {
  if (meters === undefined) {
    return "0.00";
  }
  if (meters < 1000) {
    return meters.toFixed(2);
  }
  return (meters / 1000).toFixed(2);
}

function determine_distance_unit(meters: number) {
  if (meters === undefined) {
    return "m";
  }
  if (meters < 1000) {
    return "m";
  }
  return "km";
}
