import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import useZoomableChart from "../sessions/detail/useZoomableChart";
import { formatUTCTimestamp } from "../../utils/DateFormatter";
import { Container, Spinner, Stack } from "react-bootstrap";
import { HiMagnifyingGlassMinus } from "react-icons/hi2";

interface AreaChartComponentProps {
  recordingId: string;
  xKey: string;
  yKey: string[];
}

export default function AreaChartComponent(
  this: any,
  props: AreaChartComponentProps
) {
  const {
    plotData,
    handleMouseDown,
    handleMouseMove,
    zoom,
    resetZoom,
    refAreaLeft,
    refAreaRight,
    isLoading,
  } = useZoomableChart(props.recordingId, props.xKey, props.yKey);

  return (
    <Container>
      <Stack
        gap={2}
        direction="horizontal"
        className="justify-content-end pe-4"
      >
        <Spinner variant="light" size="sm" hidden={!isLoading} />
        <HiMagnifyingGlassMinus
          onClick={resetZoom}
          style={{ cursor: "pointer" }}
        />
      </Stack>
      <Container
        className="mx-0 px-0"
        style={{ height: "30vh", width: "100%" }}
      >
        <ResponsiveContainer width="99%" height="100%">
          <AreaChart
            data={plotData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
            onMouseDown={handleMouseDown.bind(this)}
            onMouseMove={handleMouseMove.bind(this)}
            onMouseUp={zoom.bind(this)}
          >
            <defs>
              <linearGradient id="colorPrim" x1="0%" y1="0" x2="100%" y2="0">
                <stop stopColor="#FF3282" />
                <stop offset="0.0001" stopColor="#963966" />
                <stop offset="1" stopColor="#70349D" />
              </linearGradient>
              <linearGradient id="colorArea" x1="0" y1="0" x2="100%" y2="0">
                <stop stopColor="#813955" stopOpacity="0.58" />
                <stop offset="1" stopColor="#421F5B" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="0" vertical={false} opacity={0.2} />
            <XAxis
              dataKey={props.xKey}
              className="chart-label"
              stroke="#E0E0E0"
              dy={10}
              tick={{ fill: "#7E7E7E" }}
              tickFormatter={(value) => {
                if (value.includes("T")) {
                  return formatUTCTimestamp(value);
                }
                return value;
              }}
            />
            <YAxis
              className="chart-label"
              axisLine={false}
              tickLine={false}
              dx={-10}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="linear"
              dataKey={props.yKey[0]}
              stroke="url(#colorPrim)"
              fill="url(#colorArea)"
              strokeWidth={5}
            />
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </AreaChart>
        </ResponsiveContainer>
      </Container>
    </Container>
  );
}
