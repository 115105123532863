import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Recording } from "./interfaces/Recording";
import { useAppSelector } from "../../hooks";
import ExportFormatDropdown from "./ExportFormatDropdown";
import { useMemo, useState } from "react";
import { buildAcceptString } from "./lib/exportHelper";
import {
  requestWithAuthorization,
  requestWithCookie,
} from "../../network/apiRequests";
import CometSpinner from "../CometSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFilePdf,
  faGears,
  faPerson,
  faShapes,
} from "@fortawesome/free-solid-svg-icons";
import { MdContentCopy } from "react-icons/md";

export default function RecordingTable({
  userId,
  recordings,
  onBack,
  isAdmin,
}: {
  userId: string | undefined;
  recordings: Recording[];
  onBack: () => void;
  isAdmin: boolean;
}) {
  const [exportFormat, setExportFormat] = useState<string>("csv");
  const [isLoading, setIsLoading] = useState<number[]>([]);

  function linkBuilder(recordingId: string | null) {
    if (recordingId === null) {
      return "#";
    }
    return `${process.env.REACT_APP_API_HOSTNAME}/download/${recordingId}?fileType=${exportFormat}`;
  }

  function handleReportDownloadClick(
    reportId: string | null,
    e: React.MouseEvent
  ) {
    e.preventDefault();

    if (reportId === null) {
      console.log("No recording ID available");
      return;
    }

    const url = `${
      process.env.REACT_APP_API_HOSTNAME
    }/report/download?reportId=${encodeURIComponent(reportId)}`;
    requestWithAuthorization(url, { responseType: "blob" }).then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();

      // Clean up
      if (link.parentNode != null) {
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    });
  }

  async function downloadRecording(
    userId: string | undefined,
    recordingId: string | null,
    index: number
  ) {
    if (userId === undefined || recordingId === null) {
      return "#";
    }

    setIsLoading((prevLoading) => [...prevLoading, index]);

    const url = linkBuilder(recordingId);

    try {
      const response = await requestWithCookie(url);
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: buildAcceptString(exportFormat),
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `recording.${exportFormat}`);
        document.body.appendChild(link);
        link.click();
        // Clean up
        if (link.parentNode != null) {
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      }
      // Approach to start download in Browser (shows a blank page for a few seconds)
      // window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching recording: ", error);
    } finally {
      setIsLoading((prevLoading) => prevLoading.filter((i) => i !== index)); // remove the index from the array of loading indices
    }
  }

  const [sortConfig, setSortConfig] = useState({
    key: "timestamp",
    direction: "descending",
  });

  const sortedRecordings = useMemo(() => {
    let sortableRecordings = [...recordings];
    sortableRecordings.sort((a: Recording, b: Recording) => {
      if (a.timestamp < b.timestamp) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a.timestamp > b.timestamp) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableRecordings;
  }, [recordings, sortConfig]);

  const handleSort = () => {
    setSortConfig({
      key: "timestamp",
      direction:
        sortConfig.direction === "ascending" ? "descending" : "ascending",
    });
  };

  function copyRecordingId(recordingId: string | null) {
    if (recordingId === null) {
      return;
    }
    navigator.clipboard.writeText(recordingId);
  }

  return (
    <>
      <Container fluid className="pb-3">
        {isAdmin && (
          <Row>
            <Col>
              <Button variant="outline-light" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col className="justify-content-end text-end">
              <ExportFormatDropdown
                exportFormat={exportFormat}
                setExportFormat={setExportFormat}
              />
            </Col>
          </Row>
        )}
      </Container>
      <Container fluid>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Recording Name</th>
              <th onClick={handleSort} className="clickable-header">
                Start Timestamp
                {sortConfig.direction === "ascending" ? " ↓" : " ↑"}
              </th>
              <th>Duration</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {sortedRecordings.map((recording, index) => {
              const formated_timestamp = new Date(
                recording.timestamp
              ).toLocaleString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              });
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <span>{recording.name}</span>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={recording.recordingId}>
                            Copy Recording ID
                          </Tooltip>
                        }
                      >
                        <span
                          className="text-end justify-content-end"
                          onClick={() => copyRecordingId(recording.recordingId)}
                          style={{ cursor: "pointer" }}
                          title="Copy Recording ID"
                        >
                          <MdContentCopy />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>{formated_timestamp}</td>
                  <td>{recording.duration}</td>
                  <td className="text-center">
                    {isLoading.includes(index) ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      isAdmin && (
                        <>
                          <FontAwesomeIcon
                            icon={faDownload}
                            color="white"
                            size="lg"
                            className="me-3"
                            onClick={() =>
                              downloadRecording(
                                userId,
                                recording.recordingId,
                                index
                              )
                            }
                            cursor={"pointer"}
                            title="Download Raw Data"
                          />
                          <FontAwesomeIcon
                            icon={faShapes}
                            color="white"
                            size="lg"
                            className="me-3"
                            onClick={() => {
                              window.location.href = `${process.env.REACT_APP_API_HOSTNAME}/download/${recording.recordingId}?fileType=${exportFormat}&combined=true`;
                            }}
                            cursor={"pointer"}
                            title="Download Combined Data"
                          />
                          <FontAwesomeIcon
                            icon={faGears}
                            color="white"
                            size="lg"
                            className="me-3"
                            onClick={() => {
                              window.location.href = `${process.env.REACT_APP_API_HOSTNAME}/download/${recording.recordingId}?fileType=${exportFormat}&processed=true`;
                            }}
                            cursor={"pointer"}
                            title="Download Processed Data"
                          />
                        </>
                      )
                    )}
                    {recording.reportExisting && (
                      <a
                        href={"#"}
                        onClick={(e) =>
                          handleReportDownloadClick(recording.recordingId, e)
                        }
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          color="white"
                          size="lg"
                          title="Download Report"
                        />
                      </a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
