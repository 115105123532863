import React from "react";
import { Dropdown } from "react-bootstrap";


export default function ExportFormatDropdown( props: { exportFormat: string; setExportFormat: React.Dispatch<React.SetStateAction<string>>; }) {

    const { exportFormat, setExportFormat } = props;

    return(
        <Dropdown align={"end"}>
            <Dropdown.Toggle variant="outline-light" id="export-format-dropdown">
                Export Format
            </Dropdown.Toggle>

            <Dropdown.Menu variant="dark">
                <Dropdown.Item 
                    onClick={() => setExportFormat("csv")}
                    active={exportFormat === "csv"}
                >
                    CSV
                </Dropdown.Item>
                <Dropdown.Item 
                    onClick={() => setExportFormat("json")}
                    active={exportFormat === "json"}
                >
                    JSON
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}