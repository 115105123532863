import { useState } from "react";
import FilterCheckbox from "./FilterCheckbox";



export default function FatigueFilter() {
    const [checkedStates, setCheckedStates] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedStates({
      ...checkedStates,
      [event.target.id]: event.target.checked,
    });
  };

  const fatigueRanges = [
    '0-10 %',
    '11-20 %',
    '21-30 %',
    '31-40 %',
    '41-50 %',
    '51-60 %',
    '61-70 %',
    '71-80 %',
    '81-90 %',
    '91-100 %',
  ];
  
  const distanceFilterList = fatigueRanges.map((fatigue) => (
      <FilterCheckbox
      key={fatigue}
      controlId={fatigue}
      label={fatigue}
      checked={!!checkedStates[fatigue]}
      onChange={handleCheckboxChange}
      />
  ));

  return (
    <div>
      {distanceFilterList}
    </div>
  );
}