import { Row, Col } from "react-bootstrap";
import Symbol from "./Symbol";


interface KPISectionProps {
    name: string;
    value: string;
    unit: string;
    symbol: React.ReactNode;
}

export default function KPISection({ name, value, unit, symbol }: KPISectionProps) {
    return (
        <Row>
            <Col xs="auto">
                <Symbol symbol={symbol} />
            </Col>
            <Col>
                <Row>
                    <Col><span className="kpi-value">{`${value} ${unit}`}</span></Col>
                </Row>
                <Row>
                    <Col><span className="card-subheading">{name}</span></Col>
                </Row>
            </Col>
        </Row>
    )
}