import { Container, Row, Col } from "react-bootstrap";
import Symbol from "../dashboard/card/Symbol";
import { BsBicycle } from "react-icons/bs";
import { Link } from "react-router-dom";


interface SessionTileProps {
    icon: React.ReactNode;
    name: string;
    date: Date | string;
    id: string;
}

export default function SessionTile( { icon, name, date, id }: SessionTileProps) {

    // check if date is a string
    if (typeof date === "string") {
        date = new Date(date)
    }

    return (
        <Container className="session-tile p-4 d-flex justify-content-center text-center">
            <Row>
                <Col>
                    <Row className="pb-2">
                        <Col className="text-center d-flex justify-content-center"><Symbol symbol={icon} /></Col>
                    </Row>
                    <Row className="pb-2">
                        <Col><span className="session-tile-title">{name}</span></Col>
                    </Row>
                    <Row className="pb-2">
                        <Col><span className="session-tile-date">{date.toLocaleDateString()}</span></Col>
                    </Row>
                    <Row>
                        <Col><Link className="wind-link" to={"/sessions/" + id}>View</Link></Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}