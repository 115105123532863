import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SpinnerSvg } from './windpuls_comet.svg'

const bounceAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  15% {
    transform: translateY(-30%) rotate(-20deg);
  }
  30% {
    transform: translateY(-60%) rotate(-15deg);
  }
  45% {
    transform: translateY(-90%) rotate(-2.5deg);
  }
  50% {
    transform: translateY(-100%) rotate(0);
  }
  55% {
    transform: translateY(-90%) rotate(2.5deg);
  }
  70% {
    transform: translateY(-60%) rotate(15deg);
  }
  85% {
    transform: translateY(-30%) rotate(20deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
`;

const SpinnerContainer = styled.div<{ height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% + ${props => props.height}px);
  padding-top: ${props => props.height}px;
`;

const BouncingSpinner = styled(SpinnerSvg)`
  height: ${props => (props.height)}px;
  width: ${props => (props.height)}px;
  animation: ${bounceAnimation} 1.5s linear infinite;
  transform-origin: center ${props => `${props.height}px`};
`;

export default function CometSpinner( { height }: { height: number }) {
    return (
        <SpinnerContainer height={height}>
            <BouncingSpinner height={height} />
        </SpinnerContainer>
    )
}