import { Table } from "react-bootstrap";
import { User } from "./interfaces/User";
import { useNavigate } from "react-router-dom";

export default function UserTable({ users }: { users: User[] }) {
  const navigate = useNavigate();
  return (
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>#</th>
          <th>E-Mail</th>
          <th>UID</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/recordings/" + user.uid)}
          >
            <td>{index + 1}</td>
            <td>{user.email}</td>
            <td>{user.uid}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
