import { Container, Row, Col, Image} from "react-bootstrap";

interface HeroImageProps {
    imageUrl: string;
    paragraph: string;
}

export default function HeroImage({ imageUrl, paragraph }: HeroImageProps) {
    var backgroundImage = {
      backgroundImage: `linear-gradient(0deg, rgba(17, 24, 39, 0.62), rgba(17, 24, 39, 0.62)), url(${imageUrl})`,
    };

    return (
        <Container fluid className="no-padding vh-100 hero-background-image d-flex align-items-center justify-content-center" style={backgroundImage}>
            <Container fluid className="text-center hero-container-margin">
                <Row className="mx-0 pb-4">
                    <Col>
                        <Image src="/img/windpuls_logo.svg" className="hero-logo" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="subparagraph">{paragraph}</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}