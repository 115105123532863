import { Breadcrumb, Col, Container, Row, Spinner } from "react-bootstrap";
import CustomBreadcrumbs from "../navigation/breadcrumbs/CustomBreadcrumbs";
import DashboardButton from "../dashboard/DashboardButton";
import LightButton from "../LightButton";
import Searchbar from "../navigation/navbar/Searchbar";
import CalendarButton from "./CalendarButton";
import FilterButton from "./filters/FilterButton";
import TilesViewButton from "./TilesViewButton";
import ListViewButton from "./ListViewButton";
import { BsBicycle } from "react-icons/bs";
import SessionTile from "./SessionTile";
import SessionPagination from "./SessionPagination";
import { requestWithAuthorization } from "../../network/apiRequests";
import { useEffect, useState } from "react";

export default function SessionsComponent() {
  const SESSIONS_PER_PAGE = 20;

  async function fetchRecordings() {
    try {
      const response = await requestWithAuthorization(
        process.env.REACT_APP_API_HOSTNAME +
          "/recordings?limit=" +
          SESSIONS_PER_PAGE
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchRecordings()
      .then((result) => {
        if (result === undefined) {
          return;
        }
        if (result.length > 0) {
          result.sort((a: any, b: any) => {
            return new Date(b.start).getTime() - new Date(a.start).getTime();
          });
          setRecordings(result);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  /**convert recordings to session-objects
   * recordings.recordingId = session.id
   * recording.recordingName = session.name
   * recording.start = session.date
   * session.icon = <BsBicycle />
   */
  const sessions = recordings.map((recording: any) => {
    return {
      id: recording.recordingId,
      name: recording.recordingName,
      date: recording.start,
      icon: <BsBicycle />,
    };
  });

  let containerContent = <></>;
  if (loading) {
    containerContent = (
      <Row className="d-flex align-content-center" style={{ height: "70vh" }}>
        <Col className="d-flex justify-content-center">
          <Spinner animation="border" variant="light" />
        </Col>
      </Row>
    );
  } else {
    containerContent = (
      <>
        <Row className="px-1">
          {sessions.map((session) => (
            <Col xs={12} sm={4} md={3} lg={3} className="p-1">
              <SessionTile
                id={session.id}
                name={session.name}
                date={session.date}
                icon={session.icon}
              />
            </Col>
          ))}
        </Row>
        <Row className="pt-5 pb-5">
          <Col className="d-flex justify-content-center">
            <SessionPagination
              activePage={1}
              sessionsPerPage={SESSIONS_PER_PAGE}
              totalSessions={sessions.length}
            />
          </Col>
        </Row>
      </>
    );
  }

  if (recordings.length === 0 && !loading) {
    containerContent = (
      <Row className="d-flex align-content-center" style={{ height: "70vh" }}>
        <Col className="d-flex justify-content-center">
          <h1>No sessions found</h1>
        </Col>
      </Row>
    );
  }

  return (
    <Container fluid className="background-color text-white p-4">
      <Row>
        <Col>
          <CustomBreadcrumbs
            path={[{ path: "/dashboard", title: "Dashboard" }]}
            page="Sessions"
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col>
          <Row>
            <Col>
              <h1>Sessions</h1>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex justify-content-end">
          <Row>
            <Col>{/* <DashboardButton text="+ Add Session" /> */}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="detail-page-subheading">
            Get detailed insights into the environmental conditions during a
            particular session.
          </span>
        </Col>
      </Row>
      <Row className="pt-3 pb-3">
        <Col className="d-flex justify-content-start">
          <Searchbar />
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="pe-2">
            <CalendarButton startDate={new Date()} endDate={new Date()} />
          </div>
          <div className="pe-2">
            <FilterButton />
          </div>
          <div className="pe-2">
            <TilesViewButton />
          </div>
          <div>
            <ListViewButton />
          </div>
        </Col>
      </Row>
      <Container fluid className="px-1">
        {containerContent}
      </Container>
    </Container>
  );
}
