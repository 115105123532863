import { Navigate, Outlet, useLocation } from "react-router-dom";
import { currentUserIsAdmin } from "./firebase";
import { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";


export default function AdminRoutes() {

    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        async function checkAdmin() {
            const isAdmin = await currentUserIsAdmin();
            setIsAdmin(isAdmin);
            setLoading(false);
        }
        checkAdmin();
    }, []);

    if (loading) {
        return (
            <Row className="d-flex justify-content-center text-center">
                <Col className="d-flex justify-content-center align-items-center" style={{height: "80vh"}}>
                    <Spinner animation="border" variant="light" />
                </Col>
            </Row>
        )
    }

    if (!isAdmin) {
        return <Navigate to="/unauthorized" state={{ from: location }} />; // redirect to home page if not admin
    }

    return <Outlet />; // if admin, render children
}