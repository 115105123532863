
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend } from "recharts";

interface BarChartProps {
    data: Array<any>,
    xKey: string,
    yKey?: string,
    barOneKey: string,
    barTwoKey: string
}

export default function CustomBarChart( { data, xKey, yKey, barOneKey, barTwoKey }: BarChartProps) {
    return (
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <BarChart data={data} margin={{top: 0, right: 0, left: -25, bottom: 0}}>
                <CartesianGrid strokeDasharray="0" vertical={false} opacity={0.2} />
                <XAxis dataKey={xKey} className="chart-label" stroke="#E0E0E0" dy={10} tick={{fill: "#7E7E7E"}} />
                <YAxis dataKey={yKey} className="chart-label" axisLine={false} tickLine={false} dx={-10} />
                <Bar dataKey={barOneKey} fill="#70349D" />
                <Bar dataKey={barTwoKey} fill="#FF3282" />
                <Legend />
            </BarChart>
        </ResponsiveContainer>
    )
}