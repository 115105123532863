import CustomBarChart from "../../plots/CustomBarChart";

export default function StatsCard() {
  const data = [
    {
      name: "Jan",
      you: 10,
      avg: 20,
    },
    {
      name: "Feb",
      you: 20,
      avg: 14,
    },
    {
      name: "Mar",
      you: 30,
      avg: 20,
    },
    {
      name: "Apr",
      you: 5,
      avg: 22,
    },
    {
      name: "May",
      you: 0,
      avg: 23,
    },
    {
      name: "Jun",
      you: 0,
      avg: 25,
    },
    {
      name: "Jul",
      you: 0,
      avg: 26,
    },
    {
      name: "Aug",
      you: 40,
      avg: 27,
    },
    {
      name: "Sep",
      you: 33,
      avg: 28,
    },
    {
      name: "Oct",
      you: 11,
      avg: 1,
    },
    {
      name: "Nov",
      you: 3,
      avg: 0,
    },
    {
      name: "Dec",
      you: 0,
      avg: 0,
    },
  ];

  return (
    <div style={{ height: "40vh" }}>
      <CustomBarChart data={data} xKey="name" barOneKey="you" barTwoKey="avg" />
    </div>
  );
}
