import { Container, Row, Col } from "react-bootstrap";
import DashboardTable from "../components/dashboard/DashboardTable";
import DashboardComponent from "../components/dashboard/DashboardComponent";

export default function Dashboard() {
  return <DashboardComponent />;

  return (
    <Container
      fluid
      style={{ backgroundColor: "#091014", minHeight: "100vh", color: "#fff" }}
    >
      <Row className="pb-3 pt-3 text-center">
        <Col>
          <h1>Welcome to your dashboard!</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <DashboardTable />
        </Col>
      </Row>
    </Container>
  );
}
