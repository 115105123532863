import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import SmallCard from "./card/SmallCard";
import { LuWind } from "react-icons/lu";
import { GiFoxTail, GiWindsock } from "react-icons/gi";
import KPICard from "./card/KPICard";
import MapCard from "./card/MapCard";
import SessionCard from "./card/SessionCard";
import CalendarCard from "./card/CalendarCard";
import { useEffect, useState } from "react";
import { requestWithAuthorization } from "../../network/apiRequests";
import { getTimeDifference, getTimePassed } from "../../utils/TimePassed";
import { formatDate } from "../../utils/DateFormatter";
import { createKPIArrayFromSessionInfos } from "../../utils/KPIExtractor";
import BigCard from "./card/BigCard";
import StatsCard from "./card/StatsCard";
import { Recording } from "../../models/Recording";

export default function DashboardComponent() {
  // function to fetch all users from the backend
  async function fetchRecordings() {
    const LIMIT = 5;
    try {
      const response = await requestWithAuthorization(
        process.env.REACT_APP_API_HOSTNAME + "/recordings?limit=" + LIMIT
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchRecordingDetails(recording_id: string) {
    try {
      const response = await requestWithAuthorization(
        process.env.REACT_APP_API_HOSTNAME +
          "/recordings/" +
          recording_id +
          "/details"
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [recordings, setRecordings] = useState([] as Recording[]);
  const [latestRecordingDetails, setLatestRecordingDetails] = useState(
    {} as any
  );
  const [loading, setLoading] = useState(false);
  const [recordingDetailsLoading, setRecordingDetailsLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchRecordings()
      .then((result) => {
        if (result === undefined) {
          return;
        }
        if (result.length > 0) {
          // sort the recordings by attribute "start" which is of format: "2023-06-30T07:57:57+00:00" ASC
          result.sort((a: any, b: any) => {
            return new Date(b.start).getTime() - new Date(a.start).getTime();
          });
        }
        setRecordings(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      recordings === undefined ||
      recordings === null ||
      recordings.length === 0
    ) {
      return;
    }
    setRecordingDetailsLoading(true);
    fetchRecordingDetails(recordings[0].recordingId)
      .then((result) => {
        setLatestRecordingDetails(result);
      })
      .finally(() => {
        setRecordingDetailsLoading(false);
      });
  }, [recordings]);

  if (loading) {
    return (
      <Container fluid className="background-color text-white p-4">
        <Row className="vh-100 d-flex align-content-center">
          <Col className="d-flex justify-content-center">
            <Spinner animation="border" variant="light" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (
    recordings === undefined ||
    recordings === null ||
    Object.keys(recordings).length === 0
  ) {
    return (
      <Container
        fluid
        className="background-color text-white p-4 vh-100 d-flex align-content-center justify-content-center text-center"
      >
        <Row className="align-content-center">
          <Col className="justify-content-center">
            <Row>
              <Col>
                <span className="card-heading">No Recording found</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="card-text">
                  Please record a session to see your latest session here.
                </span>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col>
                <h2>Don't have a Sensor?</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="wind-btn w-100 p-3 mt-1"
                  onClick={() => {
                    window.open("https://www.windpuls.com/", "_blank");
                  }}
                >
                  Get yours
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  const timePassedString = getTimePassed(latestRecordingDetails.endDateTime);
  // format the date of the latest recording to match the following format: "12 Jan 2023"
  const latestRecordingDate = new Date(latestRecordingDetails.endDateTime);
  const formattedLatestRecordingDate =
    latestRecordingDate.getDate() +
    " " +
    latestRecordingDate.toLocaleString("default", { month: "short" }) +
    " " +
    latestRecordingDate.getFullYear();

  const KPIs = createKPIArrayFromSessionInfos(latestRecordingDetails.infos);

  const lastSessions = recordings.map((recording: any) => {
    return {
      id: recording.recordingId,
      name: recording.recordingName,
      duration: getTimeDifference(recording.start, recording.end),
      date: formatDate(recording.start),
    };
  });

  return (
    <Container fluid className="background-color text-white p-4">
      <Row>
        {/* Left side */}
        <Col>
          {/* Row with three columns */}
          <Row className="pb-2">
            <Col className="pe-1">
              <SmallCard
                name="Avg. Wind Speed"
                description="Latest session"
                value={latestRecordingDetails?.infos?.avg_windspeed?.value}
                unit={latestRecordingDetails?.infos?.avg_windspeed?.unit}
                symbol={<GiWindsock />}
                fromWhen={timePassedString}
                loading={recordingDetailsLoading}
              />
            </Col>
            <Col className="px-1">
              <SmallCard
                name="Avg. Speed"
                description="Latest session"
                value={latestRecordingDetails?.infos?.avg_speed?.value}
                unit={latestRecordingDetails?.infos?.avg_speed?.unit}
                symbol={<GiFoxTail />}
                fromWhen={timePassedString}
                loading={recordingDetailsLoading}
              />
            </Col>
            <Col className="px-1">
              <SmallCard
                name="Avg. Stability"
                description="Latest session"
                value={latestRecordingDetails?.infos?.avg_stability?.value}
                unit={latestRecordingDetails?.infos?.avg_stability?.unit}
                symbol={<LuWind />}
                fromWhen={timePassedString}
                loading={recordingDetailsLoading}
              />
            </Col>
          </Row>
          {/* Row with one column */}
          <Row className="pb-2">
            <Col className="pe-1">
              <KPICard
                sessionID={latestRecordingDetails?.id}
                heading={latestRecordingDetails.name}
                subheading={formattedLatestRecordingDate}
                kpis={KPIs}
                showButton={true}
                loading={recordingDetailsLoading}
              />
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="pe-1">
              <MapCard sessionID={recordings?.at(0)?.recordingId} />
            </Col>
          </Row>
          <Row className="pb-2">
            <Col className="pe-1">
              <SessionCard sessions={lastSessions} />
            </Col>
          </Row>
        </Col>
        {/* Right side */}
        <Col xs={3}>
          <Row className="pb-2">
            <Col className="ps-1">
              <CalendarCard />
            </Col>
          </Row>
          <Row>
            <Col className="ps-1">
              <BigCard heading="Stats" content={<StatsCard />} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
