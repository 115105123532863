import React, { useCallback } from "react";
import { useDropzone, FileRejection } from "react-dropzone";
import { Alert, Button } from "react-bootstrap";

interface Props {
  onFileUpload: (files: File[]) => void;
}

const DropzoneComponent: React.FC<Props> = ({ onFileUpload }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        // Handle rejected files
        console.error("Rejected files:", rejectedFiles);
        // Optionally, you can show an error message to the user
      }

      // Pass accepted files to the parent component
      onFileUpload(acceptedFiles);
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/octet-stream": [".fit"],
    }, // Specify the accepted file type
    multiple: false, // Only allow one file to be uploaded at a time
  });

  return (
    <div
      {...getRootProps()}
      style={{
        border: "2px dashed #ccc",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the file here...</p>
      ) : (
        <p>Drag 'n' drop a .fit file here, or click to select one</p>
      )}
      <em>(Only .fit files are accepted)</em>
    </div>
  );
};

export default DropzoneComponent;
