import { Form } from "react-bootstrap";

interface FormFieldProps {
    controlId: string;
    label: string;
    type: string;
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }
  
  const FormField: React.FC<FormFieldProps> = ({ controlId, label, type, placeholder, value, onChange }) => {
    return (
      <Form.Group controlId={controlId}>
        <Form.Label className="mt-3 wind-form-label">{label}</Form.Label>
        <Form.Control className="wind-form-control" type={type} placeholder={placeholder} value={value} onChange={onChange} />
      </Form.Group>
    );
  };
  
  export default FormField;
  