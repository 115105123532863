import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AuthState {
    token: string | null;
    authenticated: boolean;
    error: string | null;
    isLoading: boolean;
}

const initialState: AuthState = {
    token: null,
    authenticated: false,
    error: null,
    isLoading: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginUserSuccess(state, action: PayloadAction<string>) {
            state.token = action.payload;
            state.authenticated = true;
            state.error = null;
            state.isLoading = false;
        },
        loginUserError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.authenticated = false;
            state.token = null;
            state.isLoading = false;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        logoutUser(state) {
            state.token = null;
            state.authenticated = false;
            state.error = null;
            state.isLoading = false;
        }
    }
})

export const { loginUserSuccess, loginUserError, setIsLoading, logoutUser } = authSlice.actions;

export default authSlice.reducer;

export type AuthAction =
  | ReturnType<typeof loginUserSuccess>
  | ReturnType<typeof loginUserError>
  | ReturnType<typeof setIsLoading>
  | ReturnType<typeof logoutUser>;
