import { Button, Col, Container, Row } from "react-bootstrap";
import NoHeadingCard from "./NoHeadingCard";
import { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Calendar from "../Calendar";


export default function CalendarCard() {

    const [currentDate, setCurrentDate] = useState(new Date());

    const getFirstDayOfMonth = () => {
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        // In JavaScript, Sunday is 0, Monday is 1, and so on. 
        // This shifts Sunday to the end of the week.
        return firstDay === 0 ? 6 : firstDay - 1;
    };

    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const days = Array.from({length: daysInMonth}, (_, i) => i + 1);

    // Add placeholder days for the start of the month
    const placeholders = Array.from({length: getFirstDayOfMonth()}, () => null);
    const calendarDays = [...placeholders, ...days];

    // Break down the calendar days into weeks
    const weeks = [];
    for (let i = 0; i < calendarDays.length; i += 7) {
        weeks.push(calendarDays.slice(i, i + 7));
    }

    let content = (
        <Calendar />
    )

    return (
        <NoHeadingCard content={content} />
    )
}