import { ReactNode } from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';

interface ComponentProps {
    leftContent: ReactNode;
    rightContent: ReactNode;
  }

export default function TwoColumns ( { leftContent, rightContent }: ComponentProps ) {
    return (
        <Row className='g-0'>
            <Col xs={12} sm={12} lg={7} xl={7} xxl={7}>{leftContent}</Col>
            <Col>{rightContent}</Col>
        </Row>
    )
}