import { Link } from "react-router-dom";

interface SidebarLinkProps {
    link: string;
    symbol: React.ReactNode;
    text: string;
}

export default function SidebarLink({ link, symbol, text}: SidebarLinkProps) {
    return (
        <Link to={link} className="text-color sidebar-link badge-left">
            <div className="d-flex align-items-center pt-4 pb-4 ms-4">
                <div className="me-3">{symbol}</div>
                <div>{text}</div>
            </div>
        </Link>
    )
}