import { Button } from "react-bootstrap";

interface ActionButtonProps {
    icon: React.ReactNode;
}

export default function ActionButton( { icon }: ActionButtonProps) {
    return (
        <Button className="dropdown-btn dropdown-btn-text">
            {icon}
        </Button>
    )
}