import { Form, Col, Row } from "react-bootstrap";

interface FormCheckboxProps {
  controlId: string;
  label: React.ReactNode;
  additionalContent?: React.ReactNode;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({ controlId, label, additionalContent, checked, onChange }) => {
  return (
    <Form.Group controlId={controlId} as={Row} className="wind-form-check">
      <Col>
        <Form.Check type="checkbox">
          <Form.Check.Input type="checkbox" className="wind-form-checkbox" checked={checked} onChange={onChange} />
          <Form.Check.Label className="wind-form-label" style={{ paddingTop: ".25em" }}>{label}</Form.Check.Label>
        </Form.Check>
      </Col>
      {additionalContent && (
        <Col>{additionalContent}</Col>
      )}
    </Form.Group>
  );
};

export default FormCheckbox;
