import "./App.css";
import { Route, Routes } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import PublicPage from "./pages/PublicPage";
import LoginPage from "./pages/LoginPage";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import AuthenticatedRoutes from "./AuthenticatedRoute";
import { useAppSelector } from "./hooks";
import SignupPage from "./pages/SignupPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import RecordingsPage from "./pages/RecordingsPage";
import SessionsPage from "./pages/SessionsPage";
import HelpPage from "./pages/HelpPage";
import UsersPage from "./pages/UsersPage";
import SessionPage from "./pages/SessionPage";
import AdminRoutes from "./AdminRoutes";
import SettingsPage from "./pages/SettingsPage";
import StravaCallback from "./components/settings/connections/strava/pages/StravaCallback";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/public" element={<PublicPage />} />
      <Route element={<RootLayout />}>
        <Route element={<AuthenticatedRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/recordings/:userId" element={<RecordingsPage />} />
          <Route path="/sessions" element={<SessionsPage />} />
          <Route path="/sessions/:sessionId" element={<SessionPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/auth/strava/callback" element={<StravaCallback />} />
          <Route element={<AdminRoutes />}>
            <Route path="/users" element={<UsersPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}
