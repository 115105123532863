import Container from 'react-bootstrap/Container'
import { Row, Col, Button } from 'react-bootstrap'
import { useAppSelector } from '../hooks'
import Dashboard from './Dashboard'
import Welcome from './Welcome'

export default function Home() {

    const authenticated = useAppSelector((state) => state.auth.authenticated)
    const token = useAppSelector((state) => state.auth.token)

    if (authenticated) {
        return <Dashboard />
    }

    return(
        <Welcome />
    )
}